// import * as Yup from 'yup'
import i18n from '@Commons/i18n'
import yup from '@Commons/utils/yup'

export interface InputProps {
  type: 'text' | 'radio-group' | 'email' | 'phone' | 'select' | 'checkbox'
  name: string
  value: string | number | boolean
  label?: string
  options?: Opt[]
  required
}

export interface Opt {
  value: string | number
  label: string
}

const generateValidations = (field: InputProps) => {
  let schema = yup.string().nullable()
  switch (field.type) {
    case 'email':
      schema = field.required
        ? schema
            .email(i18n.t('Invalid email'))
            .required(i18n.t('Field is required'))
        : schema.email(i18n.t('Invalid email'))
      break
    case 'phone':
      schema = field.required
        ? schema.possiblePhone().required(i18n.t('Field is required'))
        : schema.possiblePhone()
      break
    default:
      schema = field.required
        ? schema.required(i18n.t('Field is required'))
        : schema
      break
  }

  return schema
}

export const getInputs = form => {
  let initialValues: { [key: string]: any } = {}

  let validationsFields: { [key: string]: any } = {}

  for (const field of form) {
    initialValues[field.name] = null

    const schema = generateValidations(field)

    validationsFields[field.name] = schema
  }

  return {
    validationSchema: yup.object({ ...validationsFields }),
    initialValues,
    inputs: form,
  }
}
