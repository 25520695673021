import Chat from "@Components/Chat"
import ChatProvider from "@Components/Chat/ChatProvider"
import { SessionContext } from "@Provider/index"
import { useContext } from "react"
import { useParams } from "react-router-dom"

export default function ViewChat() {
  const { id } = useParams()
  const { sdk } = useContext<any>(SessionContext)


  return (
    <ChatProvider sdk={sdk} key={id}>
      <Chat key={id} />

    </ChatProvider>
  )
}

