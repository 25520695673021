import styled from "styled-components"

interface IImageStyled {
  src: string
}

export const Content = styled.div`
  border-radius: 1em;
  white-space: pre-line;
  word-break: break-all;
  vertical-align: middle;
  z-index: 1;
  position: relative;
  overflow: hidden;
`

export const Image = styled.div<IImageStyled>`
  vertical-align: middle;
  width: 100px;
  padding-top: 100%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
`
