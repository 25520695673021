import React from "react"
import Text from "./text"
import api from "./api"
import contact from "./contact"
import postback from "./postback"
import button from "./button"
import generic from "./generic"
import image from "./image"
import sticker from "./sticker"
import file from "./file/reply"
import List from "./list"
import HSM from "./hsm"
import system from "./system"

export default {
  text: Text,
  generic,
  button,
  image,
  sticker,
  list: (props) => <List payload={props} />,
  file,
  postback,
  system,
  api,
  contact,
  hsm: Text,
}
