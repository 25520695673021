import { intervalToDuration, intlFormatDistance } from 'date-fns'
import { format } from 'date-fns-tz'

export const formatDate = (
  date: Date | string,
  formatString = "MMM',' d • p",
): string => {
  if (!date) return ''
  return format(new Date(date), formatString)
}

export const durationAsStringTime = (seconds: number): string => {
  const zeroPad = n => String(n).padStart(2, '0')

  const duration = intervalToDuration({ start: 0, end: seconds })
  let hour = zeroPad(0)
  let minute = zeroPad(0)
  let second = zeroPad(0)
  if (duration) {
    const { hours = 0, minutes = 0, seconds = 0 } = duration
    hour = zeroPad(hours)
    minute = zeroPad(minutes)
    second = zeroPad(seconds)
  }
  return `${hour}:${minute}:${second}`
}

export const humanize = (d1, d2 = new Date(), locale = undefined) => {
  return intlFormatDistance(d1, d2)
}
