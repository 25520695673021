import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {ptBR, enUS} from './locales'

declare module 'react-i18next' {
  // and extend them!
  interface Resources {
    ptBR: typeof ptBR
    enUS: typeof enUS
  }
}

// react-i18next versions higher than 11.11.0
declare module 'react-i18next' {
  // and extend them!
  interface CustomTypeOptions {
    // custom namespace type if you changed it
    defaultNS: 'ptBR'
    // custom resources type
    resources: {
      ptBR: typeof ptBR
      enUS: typeof enUS
    }
  }
}

export const defaultLanguage = 'en-US'

export const resources = {
  'pt-BR': {
    translation: ptBR,
  },
  'en-US': {
    translation: enUS,
  },
}

const options = {
  order: [
    'navigator',
    'querystring',
    'cookie',
    'localStorage',
    'htmlTag',
    'path',
    'subdomain',
  ],
}

const languageDetector = new LanguageDetector(null, options)

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // fallbackLng: defaultLanguage,
    // // debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    // keySeparator: "=>",
    // react: {
    //   wait: true,
    //   useSuspense: true
    // }
  })

export default {
  t: i18n.t as any,
  language: i18n.language,
  changeLanguage: i18n.changeLanguage as any,
}
