import { gql } from '@apollo/client'

export const SEND_ACTION_BUTTON = gql`
  mutation SEND_ACTION_BUTTON(
    $externalId: string!
    $ref: string
    $link: string
    $payload: ChatWebButtonPayload
    $postback: ChatWebButtonPostback
    $message: string
    $silent: boolean
  ) {
    sendActionButton(
      externalId: $externalId
      ref: $ref
      link: $link
      payload: $payload
      postback: $postback
      message: $message
      silent: $silent
    )
  }
`
export const SEND_MESSAGE = gql`
  mutation SEND_MESSAGE(
    $ref: string
    $externalId: string!
    $direction: MessageDirection!
    $type: MessageType!
    $payload: MessagePayload!
  ) {
    sendMessage(
      ref: $ref
      externalId: $externalId
      direction: $direction
      type: $type
      payload: $payload
    )
  }
`
