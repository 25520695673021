import {
  EActionKind,
  IChatFile,
  IChatState,
  TAction,
  TChatActions,
  TState,
} from '@Commons/types/chatReducer'
import { MessageOf } from '@Commons/types/message'
import { useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import reducer from './reducer'

export default function useChatReducer(
  chatId?: string,
  initialState: Partial<IChatState> = {},
): [TState, TChatActions | undefined] {
  if (!chatId) return [{}, undefined]

  const [state, dispatch] = useReducer<
    (state: TState, actions: TAction) => TState
  >(
    reducer,
    chatId
      ? {
          [chatId]: {
            chatId,
            openPreview: false,
            message: '',
            files: [],
            messages: [],
            selectedFileIndex: -1,
            replyMessage: null,
            ...initialState,
          },
        }
      : {},
  )

  const actions: TChatActions = useMemo(
    () => ({
      resetChat: (chatIdToReset: string) =>
        dispatch({
          type: EActionKind.resetChat,
          payload: { chatId: chatIdToReset },
        }),
      setMessage: (message: string) =>
        dispatch({
          type: EActionKind.setMessage,
          payload: { chatId, message },
        }),
      appendMessage: (message: string) =>
        dispatch({
          type: EActionKind.appendMessage,
          payload: { chatId, message },
        }),
      appendFiles: (files: IChatFile[]) =>
        dispatch({ type: EActionKind.appendFiles, payload: { chatId, files } }),
      setFileText: (text: string) =>
        dispatch({
          type: EActionKind.setFileText,
          payload: { chatId, text },
        }),
      removeFile: (index: number) =>
        dispatch({ type: EActionKind.removeFile, payload: { chatId, index } }),
      setFileIndex: (index: number) =>
        dispatch({
          type: EActionKind.setFileIndex,
          payload: { chatId, index },
        }),
      setReplyMessage: message =>
        dispatch({
          type: EActionKind.setReplyMessage,
          payload: { chatId, message },
        }),
      clearFiles: () =>
        dispatch({ type: EActionKind.clearFiles, payload: { chatId } }),
      setOpenPreview: (open: boolean) =>
        dispatch({
          type: EActionKind.setOpenPreview,
          payload: { chatId, open },
        }),
      setLoadingExport: (loading: boolean) =>
        dispatch({
          type: EActionKind.setLoadingExport,
          payload: { chatId, loading },
        }),
      setMessages: (messages: MessageOf<any>[]) =>
        dispatch({
          type: EActionKind.setMessages,
          payload: { chatId, messages },
        }),
      appendMessages: (messages: MessageOf<any>[]) =>
        dispatch({
          type: EActionKind.setMessages,
          payload: { chatId, messages },
        }),
      setMessageByRef: (ref: string, message: MessageOf<any>) =>
        dispatch({
          type: EActionKind.setMessageByRef,
          payload: { chatId, ref, message },
        }),
    }),
    [chatId],
  )

  return [state, actions]
}
