import { useContext } from 'react'
import { Button, Stack } from '@mui/material'
import { SessionContext } from '@Provider/index'
import { ChatContext } from '@Components/Chat/context'
import { IChatContext } from '@Commons/types/chat'
import { useNavigate, useParams } from 'react-router-dom'
import useSendMessage from '@hooks/useSendMessage'
import Message from '../Message'
import QuickReplies from '../Message/attachments/quickReplies'

export default function ListIceBreakers({ messages }) {
  const { sdk } = useContext(SessionContext)
  const { chatState, actions } = useContext<IChatContext>(ChatContext)
  const { id } = useParams()
  const navigate = useNavigate()

  const { sendButton, loading } = useSendMessage(sdk.tenant, chatState?.chatId)

  const handleClick = async icebreaker => {
    try {
      const payloadBody = {
        title: icebreaker.title,
        target: icebreaker.payload.identifier,
        payload: {},
      }

      if (
        sdk.initialPayload &&
        sdk.options?.queryParams &&
        id === 'newConversation'
      ) {
        for (const [key, value] of Object.entries(sdk.options?.queryParams)) {
          payloadBody.payload[key] = value
        }
      }

      const message = await sendButton({
        title: icebreaker.title,
        payload: JSON.stringify(payloadBody),
      })

      navigate(`/chats/${message.conversation.id}`)
    } catch (e) {
      console.log('meuerror', e)
    }
  }

  const checkLastMessage = (message, i) =>
    message.direction !== messages[i + 1]?.direction

  const renderMessages = () => {
    return (
      <Stack sx={{ flexGrow: 1, width: '100%' }}>
        {messages.map((message, i) => (
          <Message
            key={`${message.id}-${message.ref}-${message.createdAt}`}
            message={message}
            last={checkLastMessage(message, i)}
            onReply={() => {}}
          />
        ))}
      </Stack>
    )
  }

  const renderChatPreview = () => {
    return sdk.icebreakerContent ? (
      <>
        <Message
          message={{
            id: '',
            conversation: '',
            payload: {
              id: '',
              text: sdk.icebreakerContent,
              buttons: [],
              type: 'text',
            },
            ref: '',
            type: 'text',
            createdAt: new Date(),
            receiver: 'websdk|7fbc88b269b0bb3c9469d55b7fd68511',
            direction: 'in',
            sender: '5de77c89-6549-4a52-9ad9-f0dc32a47c18',
          }}
          last
        />
        <QuickReplies
          quickReplies={sdk.icebreakers.map(it => ({
            ...it,
            label: it.title,
            payload: it.payload,
          }))}
          onClick={reply => handleClick(reply)}
        />
        <Stack sx={{ flexGrow: 1, width: '100%' }} />
      </>
    ) : (
      <>
        <Stack sx={{ flexGrow: 1, width: '100%' }}>
          {messages.map((message, i) => (
            <Message
              key={`${message.id}-${message.ref}-${message.createdAt}`}
              message={message}
              last={checkLastMessage(message, i)}
              onReply={() => {}}
            />
          ))}
        </Stack>

        {!messages.length &&
          sdk.icebreakers?.map((icebreaker, i) => (
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                borderColor: 'divider2',
                borderRadius: 2,
              }}
              size="large"
              onClick={() => handleClick(icebreaker)}
              key={`icebreaker-${icebreaker.title}-${i}`}
            >
              {icebreaker.title}
            </Button>
          ))}
      </>
    )
  }

  return (
    <Stack
      className="h-100"
      alignItems="flex-end"
      justifyContent="flex-end"
      spacing={2}
    >
      {!!messages.length ? renderMessages() : renderChatPreview()}
    </Stack>
  )
}
