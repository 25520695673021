import { useContext, Fragment, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  List,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AiOutlineSend } from 'react-icons/ai'
import i18n from '@Commons/i18n'
import Header from '@Components/Header'
import ChatItem from '@Components/ChatItem'
import { SessionContext } from '@Provider/index'
import Api from '@resources/api'
import { useStoreActions, useStoreState } from 'easy-peasy'
import ChatItemSkeleton from '@Components/ChatItem/Skeleton'

export default function ListChats() {
  const navigate = useNavigate()
  const { sdk } = useContext(SessionContext)
  const { conversations, loading } = useStoreState(({ chat }: any) => ({
    conversations: chat.conversations,
    loading: chat.loadingConversations,
  }))

  const { set_conversation_messages, set_unread_messages } = useStoreActions(
    ({ chat }: any) => ({
      set_conversation_messages: chat.set_conversation_messages,
      set_unread_messages: chat.set_unread_messages,
    }),
  )

  useEffect(() => {
    set_conversation_messages({
      id: 'newConversation',
      messages: [],
    })

    set_unread_messages(false)
  }, [])

  const goToChat = chat => {
    navigate(`/chats/${chat.id}`)
  }

  const renderContent = () => {
    return (
      <>
        <Stack className="h-100 overflow-y" sx={{ flexGrow: 1 }}>
          {conversations?.length ? (
            <List disablePadding>
              {conversations.map(chat => (
                <Fragment key={`chat-item-${chat.id}`}>
                  <ListItemButton
                    onClick={() => goToChat(chat)}
                    sx={{ borderRadius: 0, py: 1.5, px: 3 }}
                  >
                    <ChatItem chat={chat} />
                  </ListItemButton>
                  <Divider sx={{ mx: 3 }} />
                </Fragment>
              ))}
            </List>
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              className="h-100"
            >
              <Typography fontWeight="bold">
                {i18n.t('No conversations')}
              </Typography>
              <Typography variant="body2">
                {i18n.t('Your conversations will be displayed here')}
              </Typography>
            </Stack>
          )}
        </Stack>

        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'absolute',
            bottom: 20,
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderColor: 'divider2',
              borderRadius: 5,
              px: 3,
            }}
            size="small"
            startIcon={<AiOutlineSend color="inherit" />}
            onClick={() => navigate('/chats/newConversation')}
          >
            {sdk?.getStarted}
          </Button>
        </Stack>
      </>
    )
  }

  const renderLoading = () => {
    return (
      <List disablePadding>
        {[1, 2, 3].map(idx => (
          <Fragment key={`chat-loading-${idx}`}>
            <Box sx={{ px: 3 }}>
              <Box sx={{ py: 1.5 }}>
                <ChatItemSkeleton key={idx} />
              </Box>
              <Divider />
            </Box>
          </Fragment>
        ))}
      </List>
    )
  }

  return (
    <Stack
      className="h-100 overflow-y-hidden"
      sx={{ bgcolor: 'background.paper', position: 'relative' }}
    >
      <Header label={i18n.t('Chats')} sdk={sdk} />
      <>
        {loading && !conversations.length ? renderLoading() : renderContent()}
      </>
    </Stack>
  )
}
