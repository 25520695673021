import {
  createTheme,
  responsiveFontSizes,
  experimental_sx as sx,
} from '@mui/material/styles'
import { darken, lighten, PaletteMode, PaletteOptions } from '@mui/material'
import InterLight from '@Assets/fonts/Inter/Inter-Light.woff2'
import InterLightItalic from '@Assets/fonts/Inter/Inter-LightItalic.woff2'
import InterRegular from '@Assets/fonts/Inter/Inter-Regular.woff2'
import InterItalic from '@Assets/fonts/Inter/Inter-Italic.woff2'
import InterMedium from '@Assets/fonts/Inter/Inter-Medium.woff2'
import InterMediumItalic from '@Assets/fonts/Inter/Inter-MediumItalic.woff2'
import InterBold from '@Assets/fonts/Inter/Inter-Bold.woff2'
import InterBoldItalic from '@Assets/fonts/Inter/Inter-BoldItalic.woff2'

export type TenantTheme = {
  primaryColor?: string
} & {
  [key: string]: any
}

const getThemePalette = (
  tenantTheme?: TenantTheme,
  mode: PaletteMode = 'light',
): PaletteOptions => {
  const primaryColor =
    mode !== 'dark' ? tenantTheme?.primaryColor || '#6617F3' : '#FFFFFF'
  const defaultMuiTheme = createTheme()
  return {
    mode,
    primary: {
      main: primaryColor,
      light: `${primaryColor}44`,
    },
    success: {
      main: '#60D66A',
    },
    ...(mode === 'dark'
      ? {
          divider2: '#215b8f',
          primaryLight: {
            light: defaultMuiTheme.palette.grey.A100,
            main: defaultMuiTheme.palette.grey.A400,
            dark: defaultMuiTheme.palette.grey.A700,
          },
          background: {
            default: '#222222',
            paper: '#343434',
          },
        }
      : {
          divider2: '#B8CEE1',
          primaryLight: {
            light: lighten(primaryColor, 0.905),
            main: lighten(primaryColor, 0.8),
            dark: lighten(primaryColor, 0.6),
            contrastText: primaryColor,
          },
          background: {
            paper: '#FFFFFF',
            default: '#F7F7F7',
          },
          text: {},
        }),
  }
}

const getTheme = (tenantTheme?: TenantTheme, mode: PaletteMode = 'dark') => {
  const themePalette = createTheme({
    palette: getThemePalette(tenantTheme, mode),
  })
  let theme = createTheme({
    ...themePalette,
    typography: {
      fontFamily: 'Inter, Roboto, sans-serif',
    },
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '0.875rem',
            borderRadius: themePalette.spacing(1),
            boxShadow: 'none',
            textTransform: 'none',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'primary',
        },
        styleOverrides: {
          root: {
            fontFamily: 'Inter',
            borderRadius: themePalette.spacing(1),
            boxShadow: 'none',
            textTransform: 'none',
          },
          sizeLarge: {
            fontSize: '1rem',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Inter';
            font-style:  normal;
            font-weight: 300;
            font-display: swap;
            src: url(${InterLight}) format("woff2");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  italic;
            font-weight: 300;
            font-display: swap;
            src: url(${InterLightItalic}) format("woff2");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  normal;
            font-weight: 400;
            font-display: swap;
            src: url(${InterRegular}) format("woff2");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  italic;
            font-weight: 400;
            font-display: swap;
            src: url(${InterItalic}) format("woff2");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  normal;
            font-weight: 500;
            font-display: swap;
            src: url(${InterMedium}) format("woff2");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  italic;
            font-weight: 500;
            font-display: swap;
            src: url(${InterMediumItalic}) format("woff2");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  normal;
            font-weight: 700;
            font-display: swap;
            src: url(${InterBold}) format("woff2");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  italic;
            font-weight: 700;
            font-display: swap;
            src: url(${InterBoldItalic}) format("woff2");
          }

          .wrapper-swiper .swiper-button-next,
          .wrapper-swiper .swiper-button-prev {
            color: ${themePalette.palette.primary.main};
            height: 50px;
            width: 50px;
            background-color: rgba(0, 0, 0, 0.15);
            border-radius: 50%;
            transition: transform 0.2s ease-in-out;

            &:after {
              font-size: 1.3rem;
              font-weight: bold;
            }
            &:hover {
              transform: scale(1.2);
            }
            &.swiper-button-disabled {
              display: none;
            }
          }
        `,
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: themePalette.spacing(1),
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: themePalette.spacing(2),
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            '& ~ .MuiSwitch-track': {
              backgroundColor:
                mode === 'dark'
                  ? lighten(themePalette.palette.background.default, 0.05)
                  : darken(themePalette.palette.background.default, 0.05),
              opacity: 1,
            },
            '&.Mui-checked ~ .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: themePalette.palette.success.main,
            },
            '&.Mui-disabled ~ .MuiSwitch-track': {
              opacity: 1,
              cursor: 'not-allowed',
            },
            '&.Mui-checked.Mui-disabled': {
              color: themePalette.palette.background.default,
              cursor: 'not-allowed',
            },
            '&.Mui-checked:not(.Mui-disabled)': {
              color: themePalette.palette.background.paper,
            },
            '&.Mui-disabled:not(.Mui-checked)': {
              color: themePalette.palette.background.default,
              cursor: 'not-allowed',
            },
          },
          sizeMedium: {
            height: 38,
            padding: 6,
            marginLeft: 4,
            marginRight: 4,
          },
          sizeSmall: {
            height: 24,
            padding: 2,
            marginLeft: 8,
            marginRight: 8,
          },
          track: {
            borderRadius: 14,
            border: '1px solid',
            borderColor: themePalette.palette.divider,
          },
          thumb: {
            boxShadow: 'none',
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          centered: true,
          variant: 'fullWidth',
        },
        styleOverrides: {
          root: {
            borderTop: '0.5px solid',
            borderBottom: '0.5px solid',
            borderColor: themePalette.palette.divider,
            '& .MuiTab-root': {
              borderRadius: 0,
              borderLeftWidth: 0.5,
              borderRightWidth: 0.5,
              borderStyle: 'solid',
              borderColor: themePalette.palette.divider,
              textTransform: 'capitalize',
            },
          },
        },
      },
      MuiStepper: {
        defaultProps: {
          nonLinear: true,
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          // line: {
          root: {
            display: 'none',
          },
        },
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            '& .MuiStepLabel-iconContainer': {
              paddingRight: 12,
            },
            '& .MuiStepLabel-label': {
              color: themePalette.palette.text.secondary,
              '&.Mui-active': {
                fontWeight: 'bold',
                color: themePalette.palette.primary.main,
              },
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            height: 20,
            width: 20,
            borderRadius: '50%',
            circle: {
              strokeWidth: '4px',
              stroke: themePalette.palette.text.secondary,
              color: themePalette.palette.background.default,
            },
            text: {
              fill: themePalette.palette.text.secondary,
              fontWeight: 'bold',
              fontSize: '1rem',
            },
            '&.Mui-active': {
              circle: {
                stroke: themePalette.palette.primary.main,
              },
              text: {
                fill: themePalette.palette.primary.main,
              },
            },
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 1,
        },
        styleOverrides: {
          root: {
            borderRadius: themePalette.spacing(2),
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            '&.Mui-active': {
              color: 'inherit',
            },
          },
        },
      },
    },
  })

  // responsive typography
  theme = responsiveFontSizes(theme)

  return theme
}

export default getTheme
