import { Box, Stack, SvgIcon, Typography } from "@mui/material"
import { AiOutlineMessage } from 'react-icons/ai'
import { humanize } from "@Commons/utils/date"
import Contact from "../Contact"
import { useContext } from "react"
import { SessionContext } from "@Provider/index"
import { LAST_MESSAGE_BY_TYPE } from "./helper"
import i18n from "@Commons/i18n"

export default function ChatItem({ chat }) {
  const { sdk } = useContext(SessionContext)

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ flexGrow: 1, width: '100%' }}
    >
      <Box >
        <Contact
          name={""}
          picture={sdk?.avatar}
        />
      </Box>

      <Stack sx={{ flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}>
        <Stack direction="row">
          {chat?.lastMessage?.direction === 'out' && (
            <Typography component="span" sx={{ fontWeight: 'bold' }}>{i18n.t('You')}:&nbsp;</Typography>
          )}
          <Typography sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }} >
            {LAST_MESSAGE_BY_TYPE[chat?.lastMessage?.type]?.(chat?.lastMessage) || ''}
          </Typography>
        </Stack>

        <Box sx={{ display: 'inline-flex' }}>
          <Typography variant="caption" color="text.secondary" >
            {humanize(new Date(chat?.lastMessage?.createdAt || new Date()))}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}