import BlockIcon from "@mui/icons-material/Block"
import i18n from "@Commons/i18n"
import * as Styled from "./styled"

export default (message) => (
  <Styled.Content key={message.timestamp}>
    <BlockIcon style={{ marginBottom: -6, marginRight: 5 }} />
    {i18n.t("Message deleted")}
  </Styled.Content>
)
