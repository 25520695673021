import styled from 'styled-components'
import avatar from '@mui/material/Avatar'

export const Header = styled.div`
  width: 100%;
  padding-bottom: 45%;
  position: relative;
  max-height: 400px;
  background: ${props => props.color};
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;

  @media (min-width: 800px) {
    padding-bottom: 0px;
    height: 400px;
  }
`

export const Overlay = styled.div`
  position: absolute;
  background: #00000044;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`
export const Avatar = styled(avatar)`
  && {
    height: 100px;
    width: 100px;
    background-color: ${({ background }) => background || '#eaeaea'} !important;
    color: #999 !important;
    border: 1px solid #cacaca;
    margin-top: -50px;
  }
`

export const CloseButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
`
