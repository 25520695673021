import { Box, Grid, Typography } from '@mui/material'
import * as Styled from './styled'
import i18n from '@Commons/i18n'

export default function GetStartedHeader({ sdk }) {
  return (
    <Grid
      container
      direction="column"
      spacing={1}
    >
      <Grid item alignSelf="center">
        <Styled.Avatar
          alt={sdk?.title[0]}
          background={sdk?.background}
          src={sdk?.avatar}
        >
          {sdk?.title[0]}
        </Styled.Avatar>
      </Grid>
      <Grid item>
        <Typography variant="h5" fontWeight={500} sx={{ lineHeight: 1 }}>
          {sdk?.title}
        </Typography>
        {sdk?.subtitle && (
          <Typography variant="caption" color="text.secondary">
            {sdk?.subtitle}
          </Typography>
        )}
      </Grid>
      {sdk?.description && (
        <Grid item>
          <Typography
            variant="body2"
            sx={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
          >
            {sdk.description}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Box mt={1}>
          <Typography variant="caption" fontWeight="bold">
            {sdk.getStartedDescription}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
