import { Typography } from '@mui/material'
import { getMessageText } from '../../helper'
import * as Styled from './styled'

export default (message, meta, setOpenImages) => {
  return (
    <Styled.Content key={message.id}>
      <Styled.Image
        src={message?.url}
        className="cursor-pointer"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          ;(window as any).open(message?.url, '_blank').focus()
        }}
      />
      <Typography>{getMessageText(message)}</Typography>
    </Styled.Content>
  )
}
