import { stringToColor } from "@Commons/utils/color"
import { Avatar, Badge, Box, Skeleton, Stack, styled, Typography } from "@mui/material"

interface IContact {
  picture?: string | null
  name: string
  loading?: boolean
  subtitle?: any
  children?: any
  online?: boolean
}

export default function Contact({ picture, name, subtitle, children, loading, online }: IContact) {
  const renderAvatar = () => {
    if (loading) {
      return <Skeleton variant="circular" height={40} width={40} />
    }

    return (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
        badgeContent={Number(Boolean(online))}
      >
        <Avatar
          src={picture || undefined}
          sx={{
            width: 40,
            height: 40,
            bgcolor: '#CACACA',
            border: 0,
          }}
          alt={name}
        >
          {name?.[0]?.toUpperCase?.()}
        </Avatar>
      </StyledBadge>
    )
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ flexGrow: 1, width: '100%' }}
      spacing={2}
    >
      {renderAvatar()}

      <Stack justifyContent="center" sx={{ flexGrow: 1 }} spacing={1}>
        <Typography fontWeight="bold" component="span" sx={{ lineHeight: 1 }}>
          {name}
        </Typography>

        {subtitle
          ? (
            <Box sx={{ display: 'inline-flex' }}>
              <Typography variant="caption" color="text.secondary" sx={{ lineHeight: 1 }}>
                {subtitle}
              </Typography>
            </Box>
          ) : !!children && (
            <Box sx={{ display: 'inline-flex' }}>
              {children}
            </Box>
          )}
      </Stack>
    </Stack>
  )
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))
