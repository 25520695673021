import { Box, Button, SvgIcon, Typography } from '@mui/material'
import { AiOutlineSend } from 'react-icons/ai'
import { ArrowForwardIosSharp } from "@mui/icons-material"

export default function GetStartedAction({ sdk, getStarted, ...rest }) {
  return (
    <Button
      startIcon={(
        <SvgIcon color="primary">
          <AiOutlineSend />
        </SvgIcon>
      )}
      endIcon={<ArrowForwardIosSharp sx={{ height: '0.65em', width: '0.65em' }} />}
      onClick={getStarted}
      variant="text"
      color="inherit"
      size="large"
      fullWidth
      {...rest}
    >
      <Typography variant="caption" fontWeight={500}>
        {sdk.getStarted}
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
    </Button>
  )
}
