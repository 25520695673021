import i18n from '@Commons/i18n'
import Input from '@Components/Input'
import { InputPhone } from '@Components/Form/InputPhone'
import { Select } from '@Components/Form/Select'
import { SessionContext } from '@Provider/index'
import { Button, MenuItem, Stack, SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { AiOutlineSend } from 'react-icons/ai'
import { getInputs } from '@Commons/utils/getInputs'
import useSendMessage from '@hooks/useSendMessage'
import { IChatContext } from '@Commons/types/chat'
import { ChatContext } from '@Components/Chat/context'
import useTenant from '@hooks/useTenant'
import useWebSocketEvent from '@hooks/useWebSocketEvent'
import { v4 as uuidv4 } from 'uuid'

export default function ListForm() {
  const { sdk } = useContext(SessionContext)
  const { chatState, actions } = useContext<IChatContext>(ChatContext)
  const navigate = useNavigate()
  const tenant = useTenant()

  const { sendButton, loading } = useSendMessage(
    tenant,
    chatState?.chatId || 'newConversation',
  )

  const { sendMessageWithAck } = useWebSocketEvent()

  const handleSubmit = async form => {
    try {
      const body = {
        payload: {
          form,
          target: sdk?.defaultFormFlow.identifier,
          params: {},
        },
      }

      if (
        sdk.initialPayload &&
        sdk.options?.queryParams &&
        chatState?.chatId === 'newConversation'
      ) {
        for (const [key, value] of Object.entries(sdk.options?.queryParams)) {
          body.payload.params[key] = value
        }
      }

      const result = await sendMessageWithAck('message', {
        id: chatState?.chatId,
        message: {
          ref: uuidv4(),
          type: 'postback',
          silent: true,
          direction: 'out',
          payload: {
            payload: JSON.stringify({
              title: 'Formulário',
              target: sdk?.defaultFormFlow.identifier,
              payload: body.payload,
            }),
          },
        },
      })

      navigate(`/chats/${result.conversation.id}`)
      // const message = await sendButton({
      //   title: 'Formulário',
      //   silent: true,
      //   payload: JSON.stringify(body),
      // })
      // navigate(`/chats/${message.conversation.id}`)
    } catch (e) {}
  }

  const { initialValues, validationSchema } = getInputs(sdk.form)

  return (
    <>
      <Formik {...{ initialValues, validationSchema }} onSubmit={handleSubmit}>
        {({ errors, setFieldValue }) => (
          <Form noValidate>
            <Stack
              className="h-100"
              alignItems="flex-end"
              justifyContent="flex-end"
              spacing={2}
            >
              {sdk?.form?.map((input, i) => {
                let labelError: string = ''
                if (errors && errors[input.name]) {
                  labelError = i18n.t(errors[input.name])
                }

                return (
                  <>
                    {input.type === 'text' ? (
                      <Input
                        key={input.name}
                        name={input.name}
                        variant="outlined"
                        label={i18n.t(input.label)}
                        margin="none"
                        placeholder={i18n.t('')}
                        fullWidth
                        required={input.required}
                      />
                    ) : input.type === 'email' ? (
                      <Input
                        key={input.name}
                        name={input.name}
                        variant="outlined"
                        type="email"
                        label={i18n.t(input.label)}
                        placeholder={'example@email.com'}
                        fullWidth
                        required={input.required}
                      />
                    ) : input.type === 'phone' ? (
                      <InputPhone
                        key={input.name}
                        fullWidth
                        id={input.name}
                        name={input.name}
                        variant="outlined"
                        label={i18n.t(input.label)}
                        onChange={e => {
                          setFieldValue(input.name, e.target.value)
                        }}
                        {...(labelError && {
                          error: labelError as any,
                          helperText: labelError,
                        })}
                        required={input.required}
                      />
                    ) : (
                      <Select
                        key={input.name}
                        name={input.name}
                        label={i18n.t(input.label)}
                        fullWidth
                        variant="outlined"
                        placeholder={i18n.t('Select an value')}
                        onChange={e => {
                          setFieldValue(input.name, e.target.value)
                        }}
                        {...(labelError && {
                          error: labelError as any,
                          helperText: labelError,
                        })}
                        required={input.required}
                      >
                        {input?.options.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </>
                )
              })}

              <Button
                startIcon={
                  <SvgIcon color="inherit">
                    <AiOutlineSend />
                  </SvgIcon>
                }
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                {i18n.t('Send Form')}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  )
}
