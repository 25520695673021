import { downloadMessageFile } from '@Commons/utils/download'
import DownloadIcon from '@mui/icons-material/ArrowDownward'
import AttachIcon from '@mui/icons-material/AttachFile'
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material'
import { getMessageText } from '../../helper'
import * as Styled from './styled'

const FileMessage = message => {
  const handleDownloadFile = () => {
    downloadMessageFile(message)
  }

  return (
    <Styled.Content key={message.id}>
      <Paper>
        <Grid container direction="row" alignItems="center">
          {message.file && (
            <Grid item>
              <Grid
                className="cursor-pointer"
                container
                direction="row"
                alignItems="center"
                spacing={1}
                // onClick={() => onClick(message)}
              >
                <Grid item style={{ lineHeight: 0 }}>
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        left: 'calc(100% - 29px)',
                        top: 'calc(100% - 29px)',
                      }}
                    >
                      <AttachIcon fontSize="small" />
                    </div>
                    <CircularProgress
                      variant="indeterminate"
                      size={36}
                      color="primary"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* <Grid item xs>
            <div className="cursor-pointer">
              {HandleType[message.type]?.(message) || message.file?.name}
            </div>
          </Grid> */}
          <Grid item>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              p={1}
              pr={2}
              wrap="nowrap"
            >
              <Grid item>
                <IconButton color="inherit" onClick={handleDownloadFile}>
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Grid>

              <Grid item sx={{ overflow: 'hidden' }}>
                <Typography
                  sx={{
                    display: '-webkit-box',
                    lineClamp: '2',
                    WebkitLineClamp: '2',
                    boxOrient: 'vertical',
                    WebkitBoxOrient: 'vertical',
                  }}
                  title={message.name}
                >
                  {message.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Typography>{getMessageText(message)}</Typography>
    </Styled.Content>
  )
}

export default props => <FileMessage {...props} onClick={props.onClick} />
