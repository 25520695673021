import { Box, Typography } from "@mui/material"
import attachmentsReply from "../attachments/reply"
import * as Styled from "./styled"

const ReplyMessage = ({ reply, sender }) => {
  if (!reply) return null

  const renderContent = () => {
    if (reply?.text) {
      return attachmentsReply.text({
        ...reply,
        replyId: reply?._id,
        payload: reply,
        reply: true,
      })
    }

    if (reply?.message?.type) {
      return attachmentsReply[reply.message.type]?.({
        ...reply.message,
        reply: true,
      })
    }

    return null
  }

  return (
    <Styled.Reply>
      <Box sx={{ py: 1 }}>
        <Typography variant="caption" color="text.secondary">
          {sender}
        </Typography>
      </Box>

      {renderContent()}
    </Styled.Reply>
  )
}

export default ReplyMessage
