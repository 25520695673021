import { MessageOf } from './message'

export interface IChatFile {
  file: File
  text: string
}

export interface IChatState {
  chatId?: string
  openPreview: boolean
  message: string
  files: IChatFile[]
  selectedFileIndex: number
  replyMessage?: any
  loadingExport?: boolean | null
  messages: MessageOf<any>[]
}

export type TState = Record<string, IChatState>

export enum EActionKind {
  resetChat = 'resetChat',
  setMessage = 'setMessage',
  appendMessage = 'appendMessage',
  setOpenPreview = 'setOpenPreview',
  appendFiles = 'appendFiles',
  removeFile = 'removeFile',
  clearFiles = 'clearFiles',
  setFileIndex = 'setFileIndex',
  setFileText = 'setFileText',
  setReplyMessage = 'setReplyMessage',
  setLoadingExport = 'setLoadingExport',
  setMessages = 'setMessages',
  appendMessages = 'appendMessages',
  setMessageByRef = 'setMessageByRef',
}

export type TActionPayload = {
  resetChat: { chatId: string }
  setMessage: { chatId: string; message: string }
  appendMessage: { chatId: string; message: string }
  setOpenPreview: { chatId: string; open: boolean }
  appendFiles: { chatId: string; files: IChatFile[] }
  removeFile: { chatId: string; index: number }
  clearFiles: { chatId: string }
  setFileIndex: { chatId: string; index: number }
  setFileText: { chatId: string; text: string }
  setReplyMessage: { chatId: string; message: any }
  setLoadingExport: { chatId: string; loading: boolean }
  setMessages: { chatId: string; messages: MessageOf<any>[] }
  appendMessages: { chatId: string; messages: MessageOf<any>[] }
  setMessageByRef: { chatId: string; ref: string; message: MessageOf<any> }
}

export type TAction =
  | {
      type: EActionKind.resetChat
      payload: TActionPayload[EActionKind.resetChat]
    }
  | {
      type: EActionKind.setMessage
      payload: TActionPayload[EActionKind.setMessage]
    }
  | {
      type: EActionKind.appendMessage
      payload: TActionPayload[EActionKind.appendMessage]
    }
  | {
      type: EActionKind.setOpenPreview
      payload: TActionPayload[EActionKind.setOpenPreview]
    }
  | {
      type: EActionKind.appendFiles
      payload: TActionPayload[EActionKind.appendFiles]
    }
  | {
      type: EActionKind.removeFile
      payload: TActionPayload[EActionKind.removeFile]
    }
  | {
      type: EActionKind.clearFiles
      payload: TActionPayload[EActionKind.clearFiles]
    }
  | {
      type: EActionKind.setFileIndex
      payload: TActionPayload[EActionKind.setFileIndex]
    }
  | {
      type: EActionKind.setFileText
      payload: TActionPayload[EActionKind.setFileText]
    }
  | {
      type: EActionKind.setReplyMessage
      payload: TActionPayload[EActionKind.setReplyMessage]
    }
  | {
      type: EActionKind.setLoadingExport
      payload: TActionPayload[EActionKind.setLoadingExport]
    }
  | {
      type: EActionKind.setMessages
      payload: TActionPayload[EActionKind.setMessages]
    }
  | {
      type: EActionKind.appendMessages
      payload: TActionPayload[EActionKind.appendMessages]
    }
  | {
      type: EActionKind.setMessageByRef
      payload: TActionPayload[EActionKind.setMessageByRef]
    }

export type TChatActions = {
  resetChat: (chatId: string) => void
  setMessage: (message: string) => void
  appendMessage: (message: string) => void
  setOpenPreview: (open: boolean) => void
  appendFiles: (files: IChatFile[]) => void
  removeFile: (index: number) => void
  clearFiles: () => void
  setFileIndex: (index: number) => void
  setFileText: (text: string) => void
  setReplyMessage: (message) => void
  setLoadingExport: (loading: boolean) => void
  setMessages: (messages: MessageOf<any>[]) => void
  appendMessages: (messages: MessageOf<any>[]) => void
  setMessageByRef: (id: string, messages: MessageOf<any>) => void
}
