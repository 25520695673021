import styled, { css } from 'styled-components'

interface ICardStyled {
  hasButtons: boolean
}

interface IMediaStyled {
  src: string
}

export const Content = styled.div`
  position: relative;
  overflow-y: hidden;
  width: 100%;
  overflow-x: auto; /* [1] */
  -webkit-overflow-scrolling: touch; /* [4] */
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }
`

const hasButtons = css`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none;
`

export const Card = styled.div<ICardStyled>`
  margin: 0;
  width: 200px;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 1em;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  ${(props) => props.hasButtons && hasButtons}
  overflow: hidden;
  position: relative;
`

export const Media = styled.div<IMediaStyled>`
  width: 100%;
  padding-bottom: 52%;
  background-size: cover;
  background-position: center center;
  background-image: url('${(props) => props.src}');
`
export const ButtonsDivider = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`
