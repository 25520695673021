import { AiOutlineHome, AiOutlineMessage } from 'react-icons/ai'
import i18n from '@Commons/i18n'
import Layout, { DefaultLayout } from '@Containers/Main/Layout'
import Home from '@Containers/Main/Home'
import ListChats from '@Containers/Main/ListChats'
import Chat from '@Containers/Main/Chat'

const main = [
  {
    path: '/',
    component: Home,
    layout: Layout,
    label: i18n.t('Home'),
    icon: <AiOutlineHome fontSize={22} />,
    showOnTabs: true,
  },
  {
    path: '/chats/:id',
    component: Chat,
    layout: DefaultLayout,
    showOnTabs: false,
  },
  {
    path: '/chats',
    component: ListChats,
    layout: Layout,
    label: i18n.t('Chats'),
    icon: <AiOutlineMessage fontSize={22} />,
    showOnTabs: true,
    showUnreadMessages: true,
  },
  {
    path: '/onlyChats',
    component: ListChats,
    layout: DefaultLayout,
    showOnTabs: false,
  },
  // {
  //   path: '/help',
  //   component: Help,
  //   layout: Layout,
  //   label: i18n.t('Help'),
  //   icon: <AiOutlineQuestionCircle fontSize={22} />,
  //   showOnTabs: true,
  // },
]

export default main
