import { useEffect, useRef, useState } from "react"
import { Box, Grid, Typography } from "@mui/material"
import Styled from "./styled"
import { getMessageText } from "../../helper"

const speeds = [1, 1.5, 2]

const AudioMessage = ({ id, url, text }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const [speed, setSpeed] = useState(1)

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = speed
    }
  }, [speed])

  const changeSpeed = () => {
    const index = speeds.findIndex((it) => it === speed)
    setSpeed(speeds[(index + 1) % 3])
  }

  return (
    <Box key={id}>
      <Grid container wrap="nowrap" alignItems="center" className="w-100">
        <Grid item className="flex">
          <Styled.SpeedButton disableRipple size="small" onClick={changeSpeed}>
            <span style={{ fontSize: 11 }}>x</span>
            <span>{Number(speed).toFixed(1)}</span>
          </Styled.SpeedButton>
        </Grid>
        <Grid item xs className="flex w-100">
          <Styled.Audio controls ref={audioRef}>
            <source src={url} type="audio/ogg" />
            <source src={url} type="audio/mpeg" />
            <span>Your browser does not support the audio element.</span>
          </Styled.Audio>
        </Grid>
      </Grid>
      <Typography>{getMessageText(text)}</Typography>
    </Box>
  )
}

export default (props) => (
  <AudioMessage {...props} />
)
