export const ONE_MB = 1000000
export const MAX_FILE_SIZE = ONE_MB * 100
export const MAX_FILES = 5

export const checkAcceptableFileWhatsapp = (file: File) => {
  if (!file) return false
  const ext = file.name.split('.').pop()!

  if (['jpeg', 'jpg', 'png'].includes(ext)) {
    return file.size < ONE_MB * 50
  }

  if (['mp4'].includes(ext)) {
    return file.size < ONE_MB * 50
  }

  return file.size < ONE_MB * 100
}

export const getAcceptedAndRejectedFiles = (
  copiedFiles: File[],
  max: number,
  fnFileHandler: ((file: File) => boolean) | null,
): [File[], File[]] => {
  const pastedFiles: File[] = []
  const rejected: File[] = []
  const allowedSize = Math.max(Math.min(copiedFiles.length, max), 0)
  fnFileHandler = fnFileHandler || (() => true)

  for (let i = 0; i < copiedFiles.length; i++) {
    const file = copiedFiles[i]
    i < allowedSize && fnFileHandler(file)
      ? pastedFiles.push(file)
      : rejected.push(file)
  }

  return [pastedFiles, rejected]
}
