import { useContext } from "react"
import { Box, IconButton, Stack, Tooltip } from "@mui/material"
import { EMOJI_ATTACHMENT, IAttachmentOption } from "../helper"
import ButtonAttachments from "./ButtonAttachments"
import { ChatContext } from "@Components/Chat/context"
import i18n from "@Commons/i18n"
import { IChatContext } from "@Commons/types/chat"

interface IFooterLeftOptions {
  options: IAttachmentOption[]
}

export default function FooterLeftOptions({
  options
}: IFooterLeftOptions) {
  const { dropzoneRef, setOpenMenu, openMenu } = useContext<IChatContext>(ChatContext)

  const handleChoose = (e, attachment: IAttachmentOption) => {
    e.stopPropagation()

    if (attachment.name === 'file') {
      if (dropzoneRef?.current) {
        setOpenMenu?.(null)
        dropzoneRef.current.open()
      }
    } else {
      if (openMenu === attachment.name) {
        setOpenMenu?.(null)
      } else {
        setOpenMenu?.(attachment.name)
      }
    }
  }

  return (
    <Stack direction="row">
      <Box>
        <ButtonAttachments
          options={options}
          onChooseOption={handleChoose}
        />
      </Box>
      <Box>
        <Tooltip title={i18n.t(EMOJI_ATTACHMENT.label)}>
          <IconButton onClick={(e) => handleChoose(e, EMOJI_ATTACHMENT)}>
            {EMOJI_ATTACHMENT.icon}
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  )
}
