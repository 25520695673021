import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { DropzoneRef } from "react-dropzone"
import { IChatContact, TMenu } from "@Commons/types/chat"
import { IChatState } from "@Commons/types/chatReducer"
import useChatReducer from "@hooks/useChatReducer"
import useChatEventListeners from "@hooks/useChatEventListeners"
import useWebSocketEvent from "@hooks/useWebSocketEvent"
import { ChatContext } from "../context"
import { useLocation, useParams } from "react-router-dom"

interface IChatFields {
  chatId?: string
  contact?: IChatContact
}

export default function ChatProvider({ children, sdk }) {
  const { id } = useParams()

  const { chatId, contact } = useMemo<IChatFields>(() => {
    return {
      chatId: id,
      contact: {
        name: sdk.title,
        picture: sdk.avatar,
      },
    }
  }, [id])

  const [openMenu, setOpenMenu] = useState<TMenu>(null)
  const [rejectedFiles, setRejectedFiles] = useState<File[]>([])

  const [state, actions] = useChatReducer(id)

  const scrollToBottom = useCallback((timeout = 0) => {
    if (!scrollRef.current) return

    setTimeout(() => {
      const { scrollHeight, offsetHeight } = scrollRef.current
      const top = scrollHeight - offsetHeight

      scrollRef.current.scrollTo({ top })
    }, timeout)
  }, [])

  // useEffect(() => {
  //   if (readyState)
  // }, [readyState])

  const chatState: IChatState | undefined = useMemo(() => {
    if (!chatId || !state) return undefined
    return state[chatId] || {}
  }, [chatId, state])

  const messageInputRef = useRef<HTMLInputElement>()
  const previewInputRef = useRef<HTMLInputElement>()
  const dropzoneRef = useRef<DropzoneRef | null>(null)
  const scrollRef = useRef<any>(null)

  useChatEventListeners({
    id,
    chatState,
    actions,
    messageInputRef,
    previewInputRef,
    setOpenMenu,
    openMenu,
    setRejectedFiles,
  })

  // if (!chatState) return null

  return (
    <ChatContext.Provider
      value={{
        id,
        contact,
        chatState,
        actions,
        openMenu,
        setOpenMenu,
        messageInputRef,
        previewInputRef,
        dropzoneRef,
        rejectedFiles,
        setRejectedFiles,
        scrollRef,
        scrollToBottom,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}
