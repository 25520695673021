import { useEffect, useRef, useState } from "react"
import { Box, Grid, Typography } from "@mui/material"
import Styled from "./styled"
import { getMessageText } from "../../helper"

const speeds = [1, 1.5, 2]

const VideoMessage = ({ id, url, text }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [speed, setSpeed] = useState(1)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = speed
    }
  }, [speed])

  const changeSpeed = () => {
    const index = speeds.findIndex((it) => it === speed)
    setSpeed(speeds[(index + 1) % 3])
  }

  return (
    <Box key={id}>
      <Grid container wrap="nowrap" alignItems="center" className="w-100">
        <Grid item xs className="flex w-100">
          <video
            controls
            ref={videoRef}
            className="w-100"
            style={{
              objectFit: 'cover',
              borderRadius: 10,
              height: 'auto',
              maxHeight: 250,
              minHeight: 150,
            }}
          >
            <source src={url} type="video/mp4" />
            <span>Your browser does not support the video tag.</span>
          </video>
        </Grid>
      </Grid>
      <Typography>{getMessageText(text)}</Typography>
    </Box>

  )
}

export default (props) => (
  <VideoMessage {...props} />
)
