import styled, { css } from 'styled-components'
import ButtonMui from '@mui/material/Button'

export const Content = styled.div`
  padding: 5px 10px;
  border-radius: 1em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: ${({ theme }) => theme.palette.divider};
`

export const Button = styled(ButtonMui)`
  && {
    background: ${({ theme }) => theme.palette.background.default} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    font-weight: bold;
    width: 100%;
    border-radius: 1em !important;
    text-align: center;
    align-items: center;
    padding: 8px 15px;
  }
`
