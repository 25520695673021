import { SelectProps } from '@mui/material'
import { FieldProps } from 'formik'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import withField from '../Field'
import { CustomTextField as TextField } from '@Components/Input'
import { TInputProps } from '@Components/Input/types'

export type CustomSelectProps = Partial<FieldProps> &
  TInputProps &
  Omit<SelectProps, 'variant'> & {
    helperTextId?: any
  }

function Select({ variant, ...props }: CustomSelectProps) {
  const fullProps: CustomSelectProps = {
    select: true,
    SelectProps: {
      IconComponent: KeyboardArrowDownIcon,
      disableUnderline: true,
      ...props,
      ...props.SelectProps,
    },
    ...props,
    variant,
  }
  return <TextField {...fullProps} />
}

const FieldSelect = withField(Select)

export { Select }
export default FieldSelect
