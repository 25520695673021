import { checkElementIsFocused } from '../helper'
import {
  checkAcceptableFileWhatsapp,
  getAcceptedAndRejectedFiles,
  MAX_FILES,
} from '@Components/Chat/ChatProvider/helper'
import { IUseEventListeners } from '@Commons/types/chatEventListeners'
import { IChatFile } from '@Commons/types/chatReducer'

// Handle paste event for file or text
// if main inputs are focused (message or preview inputs)
function pasteFileOrText(e: ClipboardEvent, data: IUseEventListeners) {
  const {
    actions,
    chatState,
    messageInputRef,
    previewInputRef,
    openMenu,
    channel,
    setRejectedFiles,
  } = data

  if (!chatState) return
  if (openMenu !== null) return
  if (!messageInputRef.current && !previewInputRef.current) return

  if (
    checkElementIsFocused(messageInputRef.current) ||
    checkElementIsFocused(previewInputRef.current)
  ) {
    return
  }

  if (e.clipboardData?.files?.length) {
    // handle pasted files
    const files: File[] = Array.from(e.clipboardData?.files)

    const [accepted, rejected] = getAcceptedAndRejectedFiles(
      files,
      MAX_FILES - (chatState?.files?.length || 0),
      channel?.type === 'whatsapp' ? checkAcceptableFileWhatsapp : null,
    )

    actions?.appendFiles(
      accepted.map((file, i) => {
        if (i === 0 && chatState?.files.length === 0) {
          return { file, text: chatState.message || '' }
        }
        return { file, text: '' }
      }),
    )

    if (rejected?.length) {
      setRejectedFiles?.(rejected)
    }

    const chatFiles: IChatFile[] = files.map((file, i) => {
      if (i === 0 && chatState.files.length === 0) {
        return { file, text: chatState.message || '' }
      }
      return { file, text: '' }
    })
    actions?.appendFiles?.(chatFiles)
  } else {
    // handle pasted text
    const text = e.clipboardData?.getData?.('text') || ''
    if (checkElementIsFocused(messageInputRef.current)) {
      actions?.appendMessage?.(text)
    } else if (checkElementIsFocused(previewInputRef.current)) {
      actions?.setFileText?.(
        `${chatState?.files[chatState?.selectedFileIndex]?.text}${text}`,
      )
    }
  }
}

export default function onDocumentPaste(
  e: ClipboardEvent,
  data: IUseEventListeners,
) {
  pasteFileOrText(e, data)
}
