import React, { useEffect } from 'react'

export function useEventListener<T extends Event = Event>(
  element: Document | Element | undefined | null,
  type: string,
  listener: (e: T) => void,
  deps?: React.DependencyList | undefined,
  options?: boolean | AddEventListenerOptions,
) {
  useEffect(() => {
    if (!element) return
    element.addEventListener(
      type,
      listener as EventListenerOrEventListenerObject,
      options,
    )
    return () => {
      element.removeEventListener(
        type,
        listener as EventListenerOrEventListenerObject,
        options,
      )
    }
  }, deps)
}
