import { AiOutlineSmile, AiOutlineFileAdd } from 'react-icons/ai'
import i18n from "@Commons/i18n"
import { TMenu } from "@Commons/types/chat"

export interface IAttachmentOption {
  label: string
  icon: any,
  name: TMenu | 'file'
  type: string
}

export const EMOJI_ATTACHMENT: IAttachmentOption = {
  label: "Emoji",
  icon: <AiOutlineSmile fontSize={24} />,
  name: "emoji",
  type: "text",
}

export const ATTACHMENT_OPTIONS: IAttachmentOption[] = [
  {
    label: i18n.t("File"),
    icon: <AiOutlineFileAdd fontSize={24} />,
    name: "file",
    type: "file",
  },
]
