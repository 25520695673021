import { Grid, Paper, Badge, Box } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { Tabs, Tab } from '@Components/Tabs'
import routes from '@routes'
import { useStoreState } from 'easy-peasy'

function TabRoute(props) {
  return (
    <Tab
      {...props}
      component={Link}
      iconPosition="top"
      to={props.path}
      label={
        <Box sx={{ position: 'relative' }}>
          {props.label}
          {props.showUnreadMessages && props.unreadMessages && (
            <Box
              sx={{
                position: 'absolute',
                width: 10,
                height: 10,
                borderRadius: '50%',
                top: -30,
                right: 0,
                bgcolor: theme => theme.palette.primary.main,
              }}
            />
          )}
        </Box>
      }
      sx={{
        '&&.MuiButtonBase-root.MuiTab-root': {
          borderLeftWidth: 0,
          borderRightWidth: 0,
          minHeight: 'unset',
        },
      }}
    />
  )
}

export default function TabsLayout({ children }) {
  const { pathname } = useLocation()
  const { unreadMessages } = useStoreState(({ chat }: any) => ({
    unreadMessages: chat.unreadMessages,
  }))

  sessionStorage.setItem('pathname', pathname)
  return (
    <Grid
      container
      direction="column"
      className="h-100 overflow-y-hidden w-100"
      wrap="nowrap"
    >
      <Grid item className="h-100 overflow-y w-100">
        {children}
      </Grid>
      <Grid item>
        <Paper sx={{ borderRadius: 0 }}>
          <Tabs
            value={pathname}
            variant="fullWidth"
            scrollButtons={false}
            sx={{
              '.MuiTabs-indicator': {
                bgcolor: 'transparent',
              },
            }}
          >
            {routes
              .filter(it => it.showOnTabs)
              .map(({ layout, showOnTabs, ...route }, i) => (
                <TabRoute
                  {...route}
                  index={i}
                  unreadMessages={unreadMessages}
                  value={route.path}
                  key={route.path}
                />
              ))}
          </Tabs>
        </Paper>
      </Grid>
    </Grid>
  )
}

export const DefaultLayout = ({ children }) => {
  const { pathname } = useLocation()
  sessionStorage.setItem('pathname', pathname)
  return <>{children}</>
}
