import { memo, useContext } from 'react'
import { MessageOf } from '@Commons/types/message'
import MessageContainer from './MessageContainer'
import ReplyMessage from './ReplyMessage'
import attachments from './attachments'
import { SessionContext } from '@Provider/index'

interface IMessage {
  message: MessageOf
  last?: boolean
  onReply?: (msg: MessageOf) => void
}

const Message = (props: IMessage) => {
  const { sdk } = useContext(SessionContext)
  const reply = props.message.reply

  return (
    <MessageContainer {...props}>
      {!!reply && (
        <ReplyMessage reply sender={reply?.meta || sdk.title || 'Bot'} />
      )}

      <div>
        {props.message.deletedAt
          ? attachments.deleted(props)
          : attachments[props.message.type]?.(props.message.payload)}
      </div>
    </MessageContainer>
  )
}

export default memo(Message)
