import { IUseEventListeners } from '@Commons/types/chatEventListeners'

// Close menus/preview on ESC key
function closeOnEscape(e: KeyboardEvent, data: IUseEventListeners) {
  if (e.key === 'Escape') {
    if (data.chatState?.openPreview) {
      data.actions?.setOpenPreview?.(false)
    }
    data.setOpenMenu(null)
  }
}

export default function onDocumentKeyUp(
  e: KeyboardEvent,
  data: IUseEventListeners,
) {
  closeOnEscape(e, data)
}
