import styled from "styled-components"

export const Content = styled.div`
  padding: 5px 10px;
  border-radius: 1em;
  z-index: 1;
  position: relative;
  white-space: pre-line;
  flex-type: column;
`
