import { v4 as uuidv4 } from 'uuid'
import {
  FileMessagePayloadType,
  MessageOf,
  MessagePayloadType,
  MessageReply,
  SendMessageOf,
} from '@Commons/types/message'
import { MessagePayloadByType } from '@Commons/types/messageTypes'

export const formatMessageWithPayload = <T extends MessagePayloadType>(
  type: T,
  payload: MessagePayloadByType[T],
  reply?: MessageOf,
): SendMessageOf<T> => {
  return {
    ref: uuidv4(),
    type,
    direction: 'out',
    payload,
    ...(reply
      ? {
          reply,
          replyId: reply.id,
        }
      : {}),
  }
}

export const formatFileMessageWithPayload = <T extends FileMessagePayloadType>(
  type: T,
  payload: MessagePayloadByType[T],
  reply?: MessageOf,
): SendMessageOf<T> => {
  return {
    ref: uuidv4(),
    type,
    direction: 'out',
    payload,
    ...(reply
      ? {
          reply,
          replyId: reply.id,
        }
      : {}),
  }
}
