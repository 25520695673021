import { useContext, useEffect, useRef } from 'react'
import { Box, Skeleton, Stack } from '@mui/material'
import Message from '../Message'
import { ChatContext } from '@Components/Chat/context'
import { IChatContext } from '@Commons/types/chat'
import ListIceBreakers from '../ListIceBreakers'
import { useParams } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'
import useSendMessage from '@hooks/useSendMessage'
import QuickReplies from '../Message/attachments/quickReplies'
import useTenant from '@hooks/useTenant'
import { SessionContext } from '@Provider/index'
import ListForm from '../ListForm'
import useWebSocketEvent from '@hooks/useWebSocketEvent'

interface IChatListMessages {
  loadingMessages?: boolean
}

export default function ChatListMessages({
  loadingMessages,
}: IChatListMessages) {
  const { chatState, scrollToBottom, actions, scrollRef } =
    useContext<IChatContext>(ChatContext)
  const { sdk } = useContext(SessionContext)
  const { id } = useParams()
  const { messages, loading } = useStoreState(({ chat }: any) => ({
    messages: chat.messages[id!] || [],
    loading: chat[`loadingMessages${id}`],
  }))
  const tenant = useTenant()
  const { sendButton } = useSendMessage(tenant, id)

  const quickReplies = messages[messages.length - 1]?.payload?.quickReplies

  const messagesRef = useRef(messages)

  const sync_messages = useStoreActions(({ chat }: any) => chat.sync_messages)

  useWebSocketEvent(
    {},
    {
      onReconnect: () => {
        sync_messages(id)
      },
    },
  )

  useEffect(() => {
    if (!messages.length) {
      sync_messages(id)
    } else {
      scrollToBottom?.()
    }
  }, [])

  useEffect(() => {
    if (!scrollRef.current) {
      return
    }
    const { scrollHeight, offsetHeight } = scrollRef.current
    const top = scrollHeight - offsetHeight
    const distance = top - scrollRef.current.scrollTop
    if (distance < 1400 || messagesRef.current.length === 0) {
      messagesRef.current = messages
      scrollToBottom?.()
    }
  }, [messages.length])

  const onReplyMessage = message => {
    actions?.setReplyMessage(message)
  }

  const handleQuickReply = quickReply => {
    sendButton({
      title: quickReply.label,
      payload: quickReply.payload,
    })
  }

  const renderLoading = () => (
    <Box alignItems="flex-start">
      <Stack justifyContent="flex-end" direction="row" sx={{ width: '100%' }}>
        <Skeleton width={80} height={40} sx={{ transform: 0 }} />
      </Stack>
      <Stack justifyContent="flex-end" direction="row" sx={{ width: '100%' }}>
        <Skeleton width={200} height={40} sx={{ transform: 0 }} />
      </Stack>

      <Stack justifyContent="flex-start" direction="row" sx={{ width: '100%' }}>
        <Skeleton width={200} height={40} sx={{ transform: 0 }} />
      </Stack>
      <Stack justifyContent="flex-start" direction="row" sx={{ width: '100%' }}>
        <Skeleton width={80} height={40} sx={{ transform: 0 }} />
      </Stack>

      <Stack justifyContent="flex-end" direction="row" sx={{ width: '100%' }}>
        <Skeleton width={80} height={40} sx={{ transform: 0 }} />
      </Stack>
      <Stack justifyContent="flex-end" direction="row" sx={{ width: '100%' }}>
        <Skeleton width={200} height={40} sx={{ transform: 0 }} />
      </Stack>

      <Stack justifyContent="flex-end" direction="row" sx={{ width: '100%' }}>
        <Skeleton width={80} height={40} sx={{ transform: 0 }} />
      </Stack>
      <Stack justifyContent="flex-end" direction="row" sx={{ width: '100%' }}>
        <Skeleton width={200} height={40} sx={{ transform: 0 }} />
      </Stack>

      <Stack justifyContent="flex-start" direction="row" sx={{ width: '100%' }}>
        <Skeleton width={200} height={40} sx={{ transform: 0 }} />
      </Stack>
      <Stack justifyContent="flex-start" direction="row" sx={{ width: '100%' }}>
        <Skeleton width={80} height={40} sx={{ transform: 0 }} />
      </Stack>
    </Box>
  )

  const checkLastMessage = (message, i) =>
    message.direction !== messages[i + 1]?.direction

  return (
    <Stack
      ref={scrollRef}
      className="h-100 w-100 overflow-y"
      sx={{ position: 'relative', p: 2 }}
    >
      <Box className="w-100" sx={{ flexGrow: 1 }}>
        {id === 'newConversation' ? (
          sdk.chatPreview === 'form' ? (
            <Stack className="h-100">
              <ListForm></ListForm>
            </Stack>
          ) : (
            <Stack className="h-100">
              <ListIceBreakers messages={messages} />
            </Stack>
          )
        ) : (
          // )
          messages.map((message, i) => (
            <Message
              key={`${message.id}-${message.ref}-${message.createdAt}`}
              message={message}
              last={checkLastMessage(message, i)}
              onReply={onReplyMessage}
            />
          ))
        )}
      </Box>

      {!!quickReplies?.length && <QuickReplies quickReplies={quickReplies} />}

      {loading && id !== 'newConversation' && renderLoading()}
    </Stack>
  )
}
