import { Storage } from 'aws-amplify'
import { formatDate } from './date'
import { v4 as uuidv4 } from 'uuid'

export async function uploadFile(
  tenant: string,
  file: File,
  key: string,
): Promise<string> {
  const cleanKey = (key.startsWith('/') ? key.slice(1) : key)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9.]/g, '')

  const trueKey = `ds/${tenant}/websdk/${formatDate(
    new Date(),
    'yyyy-MM-dd',
  )}/${uuidv4()}/${cleanKey}`

  await Storage.put(trueKey, file, {
    acl: 'public-read',
    level: 'public',
    customPrefix: {
      public: '',
    },
    contentType: file.type,
  })
  return `${process.env.REACT_APP_STORAGE_HOST}/${trueKey}`
}
