import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js/max'

export const checkValidPhone = phone =>
  isValidPhoneNumber(`${phone}`) || isValidPhoneNumber(`${phone}`, 'BR')

export const formatPhoneNumber = phone => {
  if (phone === undefined || phone === null) return ''

  const sPhone = `${phone}`.replace(/\D/g, '')

  try {
    const p = parsePhoneNumber(sPhone, 'BR')
    return p && p.isValid() && !!p.getType() ? p.formatInternational() : phone
  } catch (e) {
    console.log('phone not valid', phone)
    return phone
  }
}
