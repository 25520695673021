import { Grid, Typography } from "@mui/material"
import ContactIcon from "@mui/icons-material/AccountBox"
import { formatPhoneNumber } from "@Commons/utils/phone"
import i18n from "@Commons/i18n"
import * as Styled from "./styled"

export default ({ id, name, country, phone }) => {
  // if (Array.isArray(payload.phone)) {
  //   payload.phone = payload.phone.map((item) => formatPhoneNumber(item))
  // }
  return (
    <Styled.Content key={id}>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <ContactIcon fontSize="large" />
        </Grid>
        <Grid
          item
          xs
          zeroMinWidth
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
        >
          <Grid>
            <Typography fontWeight={500} variant="caption">
              {i18n.t("Contact")}
            </Typography>
          </Grid>
          <Grid>
            <Typography fontStyle="italic" variant="body2">
              {name}
            </Typography>
          </Grid>
          <Grid>
            <Typography fontStyle="italic" variant="body2">{`${country || ""
              } ${formatPhoneNumber(phone) || "-"}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Styled.Content>
  )
}
