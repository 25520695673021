import i18n from '@Commons/i18n'

export default function notEmpty() {
  return this.test(
    'notEmpty',
    i18n.t('minCharacters', { quantity: 1 }),
    (value) => {
      if (!value) return false
      return `${value}`.length >= 1
    },
  )
}
