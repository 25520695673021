import {
  Box,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import useHover from "@hooks/useHover"
import { formatDate } from "@Commons/utils/date"
import { checkmarks } from "./utils"
import i18n from "@Commons/i18n"
import { memo, useContext } from "react"
import { SessionContext } from "@Provider/index"

function Out(props) {
  const { sdk } = useContext(SessionContext)
  const theme = useTheme()
  const [ref, isHover]: any = useHover<any>()

  const renderCheckmark = () => {
    const { deliveredAt, readedAt, deletedAt, id, sended, sentAt } =
      props.message

    let status = "notViewed"
    if (!id && !sended && !sentAt) {
      status = "notSended"
    } else if (!!readedAt) {
      status = "readed"
    } else if (!!deliveredAt) {
      status = "delivered"
    } else if (!!deletedAt) {
      status = "deletedAt"
    }

    return checkmarks[status]
  }

  return (
    <Stack
      ref={ref}
      alignItems="flex-end"
      className="w-100"
      sx={{
        position: 'relative',
        ...(props.last && { pb: 2 }),
      }}>
      {/* {isHover && (<Typography fontWeight={300} fontSize={8} sx={{ position: 'absolute', mt: -1.5 }}>
        {formatDate(props.message.createdAt, "dd/MM/yy HH:mm")}
      </Typography>)} */}

      <Box
        sx={{
          bgcolor: sdk.senderChat || "primary.main",
          color: theme => sdk.senderTextColor || (theme.palette.mode === 'dark' ? "#000" : "#FFF"),
          borderRadius: 3,
          borderBottomRightRadius: 0,
          p: 1, pr: 4,
          maxWidth: "90%",
          position: 'relative',
          wordBreak: 'break-word',
          display: 'inline-flex',
        }}
      >
        {props.children}

        <Box sx={{ position: 'absolute', bottom: 0, right: 8 }}>
          {renderCheckmark()}
        </Box>
      </Box>

      <Typography
        fontWeight={300}
        color="text.secondary"
        fontSize={10}
        sx={{
          opacity: (props.last || isHover) ? 1 : 0,
          zIndex: 2,
        }}>
        {i18n.t('You')} · {formatDate(props.message.createdAt, "dd/MM/yy HH:mm")}
      </Typography>
    </Stack >
  )
}

export default memo(Out)
