import { useCallback, useContext } from 'react'
import { useMutation } from '@apollo/client'
import {
  FileMessagePayloadType,
  MessagePayloadType,
  SendMessageOf,
} from '@Commons/types/message'
import { SEND_MESSAGE } from '@graphql/Chat/chat.mutation'
import useWebSocketEvent from './useWebSocketEvent'
import Api from '@resources/api'
import { formatMessageWithPayload } from '@Components/Chat/formatMessage'
import { ChatContext } from '@Components/Chat/context'
import { IChatContext } from '@Commons/types/chat'
import { AnyFunction, useStoreActions } from 'easy-peasy'
import MessageSent from '@Assets/sound/message-sent.mp3'
import { Howl } from 'howler'
import { uploadFile } from '@Commons/utils/upload'

type UseSendMessageReturn = {
  sendMessage: <T extends MessagePayloadType>(
    data: SendMessageOf<T>,
    onCompleted?: (response: any) => void,
  ) => Promise<any>
  sendFile: (
    data: SendMessageOf<FileMessagePayloadType>,
    onCompleted?: (response: any) => void,
  ) => Promise<any>
  sendButton: (button: any) => Promise<any>
  loading: boolean
}

export default function useSendMessage(
  tenant: string,
  id?: string,
): UseSendMessageReturn {
  const { sendMessageWithAck } = useWebSocketEvent()

  const { new_message, update_message, update_conversation } = useStoreActions(
    ({ chat }: any) => ({
      new_message: chat.new_message,
      update_message: chat.update_message,
      update_conversation: chat.update_conversation,
    }),
  )
  const { actions } = useContext<IChatContext>(ChatContext)

  const [submitMessageMutation, { loading, client }] = useMutation(
    SEND_MESSAGE,
    {
      onError(err) {
        console.log('error to send message', err)
      },
    },
  )

  const submitMessage = useCallback(
    async <T extends MessagePayloadType>(
      message: SendMessageOf<T>,
    ): Promise<SendMessageOf<T>> => {
      message.createdAt = new Date()

      const newMessage: any = {
        id,
        message,
      }

      try {
        new_message({
          message: { ...message, conversation: id },
          newConversation: id === 'newConversation',
        })

        update_conversation({
          message,
        })

        const result = await sendMessageWithAck('message', newMessage)

        var sound = new Howl({
          src: [MessageSent],
        })

        sound.play()

        if (id === 'newConversation') {
          new_message({
            message: result.message,
            newConversation: true,
          })
          return result
        }

        update_message({
          message: result.message,
        })

        update_conversation({
          message: result.message,
        })

        return result
        // actions?.appendMessages([newMessage])
      } catch (e: any) {
        update_message({
          message: {
            ...message,
            failedAt: new Date(),
            failedReason: e?.message || e,
          },
        })

        update_conversation({
          message: {
            ...message,
            failedAt: new Date(),
            failedReason: e?.message || e,
          },
        })

        // actions?.appendMessagesWIthERROR([newMessage])
        throw e
      }
    },
    [],
  )

  const submitFile = useCallback(
    async (
      message: SendMessageOf<FileMessagePayloadType>,
    ): Promise<SendMessageOf<FileMessagePayloadType>> => {
      const {
        payload: { file },
      } = message
      delete message.payload.file
      message.createdAt = new Date()

      const newMessage: any = {
        id,
        message,
      }

      try {
        new_message({
          message: { ...message, conversation: id },
          newConversation: id === 'newConversation',
        })

        update_conversation({
          message,
        })

        const key = await uploadFile(tenant, file!, file!.name)
        message.payload.url = key
        newMessage.message.payload.url = key

        const result = await sendMessageWithAck('message', newMessage)

        var sound = new Howl({
          src: [MessageSent],
        })

        sound.play()

        update_message({
          message: result.message,
        })

        update_conversation({
          message: result.message,
        })

        return result
        // actions?.appendMessages([newMessage])
      } catch (e: any) {
        update_message({
          message: {
            ...message,
            failedAt: new Date(),
            failedReason: e.message || e,
          },
        })

        update_conversation({
          message: {
            ...message,
            failedAt: new Date(),
            failedReason: e.message || e,
          },
        })

        // actions?.appendMessagesWIthERROR([newMessage])
        throw e
      }
    },
    [],
  )

  const sendButton = useCallback(
    async (button, onCompleted?: (response: any) => Promise<any>) => {
      const data = formatMessageWithPayload<'postback'>('postback', {
        text: button.title,
        payload: button.payload,
      })

      return submitMessage<'postback'>(data)
    },
    [],
  )

  return {
    sendMessage: submitMessage,
    sendFile: submitFile,
    sendButton,
    loading,
  }
}

const play = sound =>
  new Promise((resolve, reject) => {
    const audio = new Audio()
    audio.preload = 'auto'
    audio.autoplay = true
    audio.onerror = reject
    audio.onended = resolve

    audio.src = sound
  })
