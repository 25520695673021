import { createContext, useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import url from 'url'
import { ISdk } from '@Commons/types/sdk'
import API from '@resources/api'
import ChatProvider from '@Components/Chat/ChatProvider'
import WebsocketProvider from './Websocket'
import { StoreProvider } from 'easy-peasy'
import store from '@src/store'

export const SessionContext = createContext<any>(null)

export default function Provider({ children }) {
  const [initialized, setInitialized] = useState(false)
  const [sdk, setSdk] = useState<ISdk | null>(null)
  const [muted, setMuted] = useState(false)
  const [topic, setTopic] = useState<string>('')
  const [iframe, setIframe] = useState<string>('')

  const handleButton = useCallback(async button => {
    let data = {}
    let externalId = `${Date.now()}`

    if (button.type === 'website') {
      if (button.linkType === 'internal') {
        if (top?.window) {
          top.window.location.href = button.link
        }
      } else {
        window.open(button.link, '_blank')
      }

      data = {
        link: button.link,
        payload: {
          link: button.link,
          linkType: button.linkType,
          payload: button.payload,
        },
        silent: true,
      }
    } else {
      // addMessageToCache<'text'>({
      //   ref: externalId,
      //   direction: 'in',
      //   payload: { text: button.label },
      //   type: 'text',
      //   externalId,
      // })

      data = {
        postback: {
          payload: button.payload,
          title: button.label,
        },
      }
    }

    await API.send(data)
  }, [])

  const toggleMuted = useCallback(() => {
    setMuted(prev => !prev)
  }, [])

  const onClose = useCallback(() => {
    window.parent.postMessage('toggle', '*')
  }, [])

  const init = useCallback(async () => {
    if (!sdk) return

    try {
      if (!sessionStorage.getItem('token')) {
        sessionStorage.setItem('token', sdk.token)
      }

      if (!initialized) window.parent.postMessage('websdk|resetMessages', '*')
      else window.parent.postMessage('togglePopup', '*')
    } catch (e) {}

    setTopic(
      sdk.token
        ? `websdk|${sdk.token}|${
            localStorage.getItem('fingerprint') || (window as any).fingerprint
          }`
        : `websdk|${
            localStorage.getItem('fingerprint') || (window as any).fingerprint
          }`,
    )
  }, [sdk, initialized])

  const getStarted = useCallback(() => {
    if (!sdk) return

    const target = sdk.flow.identifier
    let payload = {}

    if (sdk.initialPayload) {
      payload = {
        host: sdk.host[0],
        params: sdk.options?.queryParams,
      }
    }

    handleButton({
      title: sdk.getStarted,
      payload: `${target}|${JSON.stringify(payload)}`,
    })
  }, [sdk])

  const handlePostMessageEvent = useCallback(async event => {
    const data = event.data

    if (data.event === 'init') {
      data.token && sessionStorage.setItem('token', data.token)
      await initClient(data)
      setSdk({
        ...data,
        ...(!data.receiverChat && {
          receiverChat: `${data.color}1A`,
        }),
        ...(!data.senderChat && {
          senderChat: data.color,
        }),
      })
    } else if (data.event === 'closeWebview') {
      setIframe('')
    } else if (data.event === 'initFlow') {
      let title = 'Init Flow'
      let externalId = `${Date.now()}`

      try {
        data.payload = JSON.stringify(data.payload)
      } catch (e) {
        data.payload = '{}'
      }
      if (data.message) {
        title = data.message

        // addMessageToCache<'text'>({
        //   direction: 'in',
        //   payload: { text: title },
        //   type: "text",
        //   ref: externalId,
        //   externalId,
        // })
      }

      // sendActionButton({
      //   variables: {
      //     postback: {
      //       payload: `${data.flowId}|${data.payload}`,
      //       title,
      //     },
      //     externalId,
      //     silent: title === "Init Flow",
      //   },
      // })
    } else if (data.event === 'clear') {
      localStorage.removeItem('fingerprint')
      delete (window as any).fingerprint
      sessionStorage.setItem('pathname', '/')
      window.location.reload()
      // win
    }
  }, [])

  const handleGetStartedEvent = useCallback(
    async event => {
      const data = event.data
      if (data.event === 'getStarted') {
        getStarted()
      }
    },
    [sdk, getStarted],
  )

  const initClient = async sdk => {
    const windowData = window as any
    windowData.fingerprint = localStorage.getItem('fingerprint')

    if (sdk.hyperSenderToken) {
      try {
        const {
          data: { sender },
        } = await API.initializeUser(sdk.hyperSenderToken)
        windowData.fingerprint = sender
        localStorage.removeItem('fingerprint')
        return
      } catch (e) {
        throw 'INVALID_CONFIGURATION'
      }
    }

    if (!windowData.fingerprint) {
      windowData.fingerprint = uuid()
      localStorage.setItem('fingerprint', windowData.fingerprint)
    }
  }

  useEffect(() => {
    window.parent.postMessage('ready', '*')
    window.addEventListener('message', handlePostMessageEvent)
    return () => {
      window.removeEventListener('message', handlePostMessageEvent)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', handleGetStartedEvent)
    return () => {
      window.removeEventListener('message', handleGetStartedEvent)
    }
  }, [handleGetStartedEvent])

  useEffect(() => {
    if (sdk) {
      init()
    }
  }, [sdk])

  return sdk ? (
    <SessionContext.Provider
      value={{
        sdk,
        handleButton,
        getStarted,
        muted,
        toggleMuted,
        onClose,
        initialized,
        setIframe,
        iframe,
        init,
      }}
    >
      <StoreProvider store={store}>
        <WebsocketProvider queryParams={sdk.options?.queryParams}>
          {children}
        </WebsocketProvider>
      </StoreProvider>
    </SessionContext.Provider>
  ) : null
}
