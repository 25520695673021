import { useContext } from 'react'
import { SessionContext } from '@Provider/index'
import GetStarted from '@Components/GetStarted'
import GetStartedHeader from '@Components/GetStarted/Header'
import ChatItem from '@Components/ChatItem'
import { Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function Home() {
  const { initialized, sdk } = useContext(SessionContext)
  const navigate = useNavigate()

  return (
    <Stack className="fullHeight">
      <GetStarted>
        {initialized ? (
          <ChatItem chat={null} />
        ) : (
          <GetStartedHeader sdk={sdk} />
        )}
      </GetStarted>
    </Stack>
  )
}
