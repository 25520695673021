import { useState } from 'react'
import { Divider, Grid, Box, Typography, IconButton } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { format } from '@flasd/whatsapp-formatting'
import reactHtmlParser from 'react-html-parser'
import Linkify from 'react-linkify'
import Buttons from '../utils/Buttons'
import * as Styled from './styled'

const ITEM_WIDTH = 200 + 8

export default message => {
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)

  const onPrevious = () => {
    setPage(prev => Math.max(0, prev - 1))
    setOffset(prev => Math.max(0, prev - ITEM_WIDTH))
  }

  const onNext = () => {
    setPage(prev => Math.min(message.elements.length - 1, prev + 1))
    setOffset(prev =>
      Math.min(ITEM_WIDTH * message.elements.length, prev + ITEM_WIDTH),
    )
  }

  return (
    <Box>
      {page > 0 && (
        <IconButton
          size="small"
          sx={{
            bgcolor: 'background.default',
            '&:hover': {
              bgcolor: 'background.default',
            },
            zIndex: 2,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '5px',
            border: '1px solid',
            borderColor: 'divider',
          }}
          onClick={onPrevious}
        >
          <ArrowBack />
        </IconButton>
      )}

      <Grid
        container
        direction="row"
        wrap="nowrap"
        spacing={1}
        sx={{
          position: 'relative',
          transform: `translateX(-${offset}px)`,
          transition: ({ transitions: { duration, easing } }) =>
            `transform ${duration.complex}ms ${easing.easeInOut}`,
        }}
      >
        {message.elements?.map((element, index) => {
          const hasButtons = element.buttons?.length
          return (
            <Grid item key={index}>
              <Styled.Card hasButtons={Boolean(hasButtons)}>
                <Styled.Media src={element.image} />
                <Box sx={{ p: 1 }}>
                  <div>
                    <Typography fontWeight={500}>
                      {typeof element.title === 'string' && (
                        <Linkify
                          properties={{
                            target: '_blank',
                            style: { textDecoration: 'underline' },
                          }}
                        >
                          {reactHtmlParser(format(element.title))}
                        </Linkify>
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Typography color="text.secondary">
                      {typeof element.subtitle === 'string' && (
                        <Linkify
                          properties={{
                            target: '_blank',
                            style: { textDecoration: 'underline' },
                          }}
                        >
                          {reactHtmlParser(format(element.subtitle))}
                        </Linkify>
                      )}
                    </Typography>
                  </div>
                </Box>
              </Styled.Card>
              <Buttons buttons={element.buttons} />
            </Grid>
          )
        })}
      </Grid>

      {page < message.elements.length - 1 && (
        <IconButton
          size="small"
          sx={{
            bgcolor: 'background.default',
            '&:hover': {
              bgcolor: 'background.default',
            },
            zIndex: 2,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: '4px',
            border: '1px solid',
            borderColor: 'divider',
          }}
          onClick={onNext}
        >
          <ArrowForward />
        </IconButton>
      )}
    </Box>
  )
}
