import styled from 'styled-components'

interface IImageStyled {
  src: string
}

export const Content = styled.div`
  border-radius: 1em;
  white-space: pre-line;
  word-break: break-all;
  vertical-align: middle;
  z-index: 1;
  position: relative;
  overflow: hidden;
`

export const Image = styled.img<IImageStyled>`
  vertical-align: middle;
  max-width: 300px;
  width: 100%;
  max-height: 500px;
  height: 100%;
  border-radius: 1em;
  /*
  padding-top: 100%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  */
`
