import { getIn } from 'formik'
import i18n from '@Commons/i18n'
import { hasShallowDiff } from '@Commons/utils/list'

const getHelperId = (text, id) => (text && id ? `${id}-helper-text` : undefined)

// eslint-disable-next-line complexity
const getErrorString = (error) => {
  if (!error) {
    return error
  }
  if (Array.isArray(error)) {
    return error.filter((it) => typeof it === 'string')[0] || 'fieldInvalid'
  }
  if (typeof error === 'object' && error !== null) {
    const keyNames = Object.keys(error)
    return typeof error[keyNames[0]] === 'string'
      ? error[keyNames[0]]
      : 'fieldInvalid'
  }
  return typeof error === 'string' ? error : 'fieldInvalid'
}

const getFieldProps = (props) => {
  if (!props.field || !props.form) return props

  const { field, form, ...otherProps } = props
  const { touched, errors } = form
  const error =
    props.error ||
    getErrorString(getIn(touched, field.name) && getIn(errors, field.name))
  const hasError = !!error
  const helperText = hasError ? i18n.t(error) : props.helperText || null
  const helperTextId = getHelperId(helperText, otherProps.id)

  const onChange = () => otherProps.onChange || field.onChange
  const onBlur = () => otherProps.onBlur || field.onBlur

  const data = {
    ...field,
    ...otherProps,
    onChange: onChange(),
    onBlur: onBlur(),
    helperText,
    // helperTextId,
    error: hasError,
  }
  return data
}

const getSwitchProps = ({ field, form, ...props }) => ({
  ...field,
  ...props,
  value: field.value,
  checked: field.value,
})

const getOtherProps = (props) => {
  const { field, form, ...otherProps } = props

  return otherProps
}

const areEqual = (prevProps, nextProps) => {
  const field = nextProps.field.name
  const test = (type) =>
    getIn(prevProps.form[type], field) !== getIn(nextProps.form[type], field)
  const diffValue = prevProps.field.value !== nextProps.field.value
  const fields = ['errors', 'touched', 'isSubmitting']

  if (diffValue || fields.some(test)) {
    return false
  }

  const prevOtherProps = getOtherProps(prevProps)
  const nextOtherProps = getOtherProps(nextProps)

  return hasShallowDiff(prevOtherProps, nextOtherProps)
}

export { getHelperId, getFieldProps, getSwitchProps, areEqual }
