//@ts-nocheck

import * as yup from 'yup'
import { AnyObject, Maybe } from 'yup/lib/types'
import locale from './locale'
import { cellphone } from './cellphone'
import { phone, possiblePhone } from './phone'
import cnpj from './cnpj'
import cpf from './cpf'
import cpfCnpj from './cpfCnpj'
import date from './date'
import noSpecialChar from './noSpecialChar'
import notEmpty from './notEmpty'

yup.setLocale(locale)

yup.addMethod(yup.string, 'cellphone', cellphone)
yup.addMethod(yup.string, 'phone', phone)
yup.addMethod(yup.string, 'possiblePhone', possiblePhone)
yup.addMethod(yup.string, 'cnpj', cnpj)
yup.addMethod(yup.string, 'cpf', cpf)
yup.addMethod(yup.string, 'cpfCnpj', cpfCnpj)
yup.addMethod(yup.string, 'date', date)
yup.addMethod(yup.string, 'noSpecialChar', noSpecialChar)
yup.addMethod(yup.string, 'notEmpty', notEmpty)

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    cellphone(): StringSchema<TType, TContext>
    phone(): StringSchema<TType, TContext>
    possiblePhone(): StringSchema<TType, TContext>
    cnpj(): StringSchema<TType, TContext>
    cpf(): StringSchema<TType, TContext>
    cpfCnpj(): StringSchema<TType, TContext>
    date(): StringSchema<TType, TContext>
    noSpecialChar(): StringSchema<TType, TContext>
    notEmpty(): StringSchema<TType, TContext>
  }
}

export default yup
