import { ApolloClient, InMemoryCache } from '@apollo/client'
import { RestLink } from 'apollo-link-rest'

const httpLink = new RestLink({
  uri: `${process.env.REACT_APP_API_URL}`,
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    possibleTypes: {
      ChatConnection: ['Chat'],
    },
    typePolicies: {
      Chat: {
        keyFields: ['protocol'],
      },
    },
  }),
})

export default client
