import { Box, Skeleton, Stack } from "@mui/material"
import Contact from "../Contact"

export default function ChatItemSkeleton() {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ flexGrow: 1, width: '100%' }}
    >
      <Box >
        <Contact
          name={""}
          picture={null}
        />
      </Box>

      <Stack sx={{ flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}>
        <Box>
          <Skeleton />
        </Box>

        <Box sx={{ display: 'inline-flex' }}>
          <Skeleton height={16} width={70} />
        </Box>
      </Stack>
    </Stack>
  )
}