import { Box, Tabs as MuiTabs, Tab, BoxProps, TabsProps } from "@mui/material"

function Tabs(props: TabsProps) {
  return <MuiTabs {...props} />
}

type TabPanelProps = React.HTMLAttributes<HTMLDivElement> &
  BoxProps & {
    children?: React.ReactNode
    index: number
    value: number
  }

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  )
}

export { Tabs, Tab, TabPanel }
