import { useContext } from 'react'
import Picker from "@emoji-mart/react"
import { Box, ClickAwayListener, useTheme } from '@mui/material'
import { ChatContext } from '@Components/Chat/context'
import { getLanguage } from '@Commons/utils/locale'
import { IChatContext } from '@Commons/types/chat'

const bannedEmojis = ["middle_finger", "hankey"]

const mapEmojiLocale = {
  'pt-BR': 'pt',
  'en-US': 'en',
}

export default function SlideMenus() {
  const theme = useTheme()
  const { openMenu, setOpenMenu, actions } = useContext<IChatContext>(ChatContext)

  const handleSelectEmoji = (emoji) => {
    if (!bannedEmojis.includes(emoji.id)) {
      actions?.appendMessage(emoji.native)
    }
  }

  return (
    <>
      {openMenu === 'emoji' && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            zIndex: 5,
          }}
        >
          <Picker
            theme={theme.palette.mode}
            set="apple"
            navPosition="bottom"
            searchPosition="none"
            previewPosition="none"
            dinamicWidth
            maxFrequentRows={1}
            locale={mapEmojiLocale[getLanguage()]}
            onEmojiSelect={handleSelectEmoji}
            style={{ height: 400 }}
          />
        </Box>
      )}
    </>
  )
}
