import { useContext } from "react"
import { Box, Divider, Grid, IconButton, Paper, Stack } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { SessionContext } from "@Provider/index"
import GetStartedAction from "./Action"
import * as Styled from './styled'
import { useNavigate } from "react-router-dom"

export default function GetStarted({
  children,
}) {
  const navigate = useNavigate()
  const { sdk, onClose, getStarted } = useContext(SessionContext)

  return (
    <Grid container direction="column" className="h-100">
      <Grid item>
        <Styled.Header src={sdk?.cover} color={sdk?.color}>
          <Styled.Overlay />
          {!sdk?.options.embedded && (
            <Styled.CloseButton>
              <IconButton color="inherit" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Styled.CloseButton>
          )}
        </Styled.Header>
      </Grid>

      <Grid item xs>
        <Paper
          elevation={3}
          sx={{
            mx: 3,
            mt: -3,
            position: 'relative',
          }}
        >
          <Stack direction="column">
            <Box sx={{ px: 3, pt: 3 }}>{children}</Box>

            <Divider flexItem sx={{ mt: 1, mx: 3 }} />

            <GetStartedAction
              sdk={sdk}
              getStarted={() => navigate('/chats/newConversation')}
              sx={{
                px: 3,
                py: 1.5,
                borderRadius: 0,
                borderBottomLeftRadius: theme => theme.spacing(2),
                borderBottomRightRadius: theme => theme.spacing(2),
              }}
            />
          </Stack>
        </Paper>
      </Grid>

    </Grid>
  )
}
