import i18n from '../../i18n'

const locale = {
  mixed: {
    default: 'fieldInvalid',
    required: i18n.t('fieldRequired'),
    notNull: 'fieldNotNull',
  },
  string: {
    email: 'emailInvalid',
    min: ({ min }) => i18n.t('minCharacters', { quantity: min }),
    max: ({ max }) => i18n.t('maxCharacters', { quantity: max }),
  },
  number: {
    min: ({ min }) => i18n.t('minimumValue', { number: min }),
    max: ({ max }) => i18n.t('maximumValue', { number: max }),
  },
  array: {
    min: ({ path, min }) => i18n.t('minimiumArraySize', { path, min }),
    max: ({ path, max }) => i18n.t('maximiumArraySize', { path, max }),
  },
}

export default locale
