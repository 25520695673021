import styled from "styled-components"
import { Grid } from "@mui/material"

export const Content = styled(Grid)`
  padding: 15px;
  border-radius: 1em;
  white-space: pre-line;
  padding: 5px 10px;
  text-align: center;
  background: #fff;
  color: #000;
  margin: 5px;
  margin-bottom: 0px;
  z-index: 1;
  position: relative;
`

export const Protocol = styled.div`
  padding: 4px;
  border-radius: 0.4em;
  background: ${({ theme }) => theme.palette.primary.main};
  color: #fff;
  margin: 5px;
  z-index: 1;
  display: inline;
  position: relative;
`
export const Observations = styled.fieldset`
  border-color: ${({ theme }) => theme.palette.primary.main};
  text-align: justify;
  margin-bottom: 10px;
  && legend {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
