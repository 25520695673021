import { useContext, useEffect, useRef, useState } from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { ReadyState } from 'react-use-websocket'
import useWebSocketEvent from '@hooks/useWebSocketEvent'
import i18n from '@Commons/i18n'
import ListMessages from './components/ListMessages'
import Header from './components/Header'
import Dropzone from './components/Dropzone'
import Footer from './components/Footer'
import { SessionContext } from '@Provider/index'
import { ChatContext } from './context'
import { useLocation, useParams } from 'react-router-dom'
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'

export default function Chat() {
  const { sdk, iframe, setIframe } = useContext(SessionContext)
  // const { id } = useContext(ChatContext)
  const ref = useRef<any>()
  const { id } = useParams()

  useEffect(() => {
    if (id === 'newConversation') {
      window.parent.postMessage('websdk|getStarted', '*')
    }
  }, [id])

  const { readyState } = useWebSocketEvent({
    webview: (message) => {
      console.log('meu webview', message)
      setIframe('')
    },
    keyboard: (message) => {
      console.log('meu keyboard', message)
      setIframe('')
    },
  })

  const onDismiss = () => {
    setIframe('')
  }

  const renderContent = (dropzoneProps?: any) => {
    return (
      <Grid
        container
        direction="column"
        className="h-100 overflow-y-hidden w-100"
      >
        <Grid
          item
          xs
          className="h-100 overflow-y-hidden w-100"
          sx={{ bgcolor: 'background.default' }}
        >
          <ListMessages key={id} />
        </Grid>

        {
          ((sdk.icebreakers.length === 0 &&
            sdk.form.length === 0 &&
            id === "newConversation")
            || id !== "newConversation") ?
            (
              <Grid item>
                <Footer />
              </Grid>
            ) : null
          }

        {sdk?.showPoweredBy && (
          <Grid item alignSelf="center">
            <Box sx={{ fontSize: '0.6rem' }}>
              <>Powered by</>
              &nbsp;
              <a
                href={sdk.poweredByLink || '#'}
                target="_blank"
                rel="noopener noreferrer"
              >
                {sdk.poweredByText}
              </a>
            </Box>
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <Grid
      container
      direction="column"
      className="h-100 overflow-y-hidden w-100"
    >
      <Grid item>
        <Header sdk={sdk} />
      </Grid>

      <Divider />

      <Grid item>
        <Typography
          variant="caption"
          color="text.secondary"
          align="center"
          sx={{
            opacity: [ReadyState.CONNECTING, ReadyState.CLOSED].includes(
              readyState,
            )
              ? 1
              : 0,
            lineHeight: 1,
          }}
          display="block"
        >
          <>
            {readyState === ReadyState.CONNECTING &&
              `${i18n.t('Connecting')}...`}
            {readyState === ReadyState.CLOSED && i18n.t('Disconnected')}
          </>
        </Typography>
      </Grid>

      <Grid
        item
        xs
        className="h-100 overflow-y-hidden w-100"
        sx={{ bgcolor: 'background.paper' }}
      >
        {sdk?.allowUpload ? (
          <Dropzone>{renderContent}</Dropzone>
        ) : (
          renderContent()
        )}
      </Grid>

      <BottomSheet open={iframe}
        ref={ref}
        onDismiss={onDismiss}
        defaultSnap={({ snapPoints, lastSnap }) =>
          lastSnap ?? Math.min(...snapPoints)
        }
        snapPoints={({ maxHeight }) => [
          maxHeight * 0.75,
          maxHeight * 0.9,
        ]} >

        <iframe
          src={iframe}
          style={{ width: '100%', height: '100%', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, }}
        />
      </BottomSheet>
    </Grid>
  )
}
