import React from 'react'
import PropTypes from 'prop-types'
import MuiFormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { FormControlProps } from '@mui/material'
import { TInputProps } from '../../Input/types'
import { isCustomVariant } from '../../Input'
import { FieldProps } from 'formik'

type WithFormControlProps<T> = T &
  Partial<FieldProps> &
  Omit<FormControlProps, 'variant'> &
  TInputProps & {
    Input?: any
    Label?: any
    helperTextId?: any
    FormControlProps?: FormControlProps
    defaultValue?: any
    showFormControlLabel?: boolean
  }

function withFormControl<T = any>(
  Input: any,
  defaultProps: Partial<WithFormControlProps<T>> = {},
  Label = InputLabel,
) {
  return (props: WithFormControlProps<T>) => {
    return (
      <FormControl {...defaultProps} {...props} Input={Input} Label={Label} />
    )
  }
}

const FormControl: React.FC<WithFormControlProps<any>> = ({
  Input,
  Label,
  variant,
  ...props
}) => {
  const {
    label,
    helperText,
    helperTextId,
    fullWidth,
    disabled,
    error,
    required,
    InputProps,
    InputLabelProps,
    FormControlProps,
    FormHelperTextProps,
    showFormControlLabel = true,
    ...rest
  } = props
  const trueVariant = isCustomVariant(variant) ? 'standard' : variant

  return (
    <MuiFormControl
      variant={trueVariant}
      fullWidth={fullWidth}
      disabled={disabled}
      error={error}
      required={required}
      {...FormControlProps}
    >
      {!!label && showFormControlLabel && (
        <Label
          variant={trueVariant}
          disabled={disabled}
          error={error}
          required={required}
          {...InputLabelProps}
        >
          {label}
        </Label>
      )}
      <Input
        {...rest}
        variant={variant}
        fullWidth={fullWidth}
        disabled={disabled}
        error={error}
        required={required}
        aria-describedby={helperTextId}
        label={label}
        {...InputProps}
      />
      <FormHelperText
        id={helperTextId}
        disabled={disabled}
        error={error}
        required={required}
        {...FormHelperTextProps}
      >
        {helperText || ''}
      </FormHelperText>
    </MuiFormControl>
  )
}

FormControl.propTypes = {
  Input: PropTypes.any.isRequired,
  Label: PropTypes.any.isRequired,
}

export default withFormControl
