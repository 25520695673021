import { useContext, useState } from "react"
import Dropzone, { DropzoneState, FileRejection } from "react-dropzone"
import { Box, Grid, Slide, Typography } from "@mui/material"
import i18n from "@Commons/i18n"
import AlertDialog from "@Components/Dialog/AlertDialog"
import Filetype from "@Components/Filetype"
import { escapeTextMessage, zIndexInput } from "@Components/Chat/helper"
import PreviewFiles from "./components/PreviewFiles"
import { ChatContext } from "../../context"
import {
  checkAcceptableFileWhatsapp,
  getAcceptedAndRejectedFiles,
  MAX_FILES,
  MAX_FILE_SIZE,
  ONE_MB
} from "@Components/Chat/ChatProvider/helper"
import { IChatFile } from "@Commons/types/chatReducer"
import { IChatContext } from "@Commons/types/chat"
import { useParams } from "react-router-dom"
import useSendMessage from "@hooks/useSendMessage"
import useTenant from "@hooks/useTenant"
import { formatFileMessageWithPayload } from "@Components/Chat/formatMessage"
import { FileMessagePayloadType } from "@Commons/types/message"

interface IChatDropzoneProps {
  children: (
    dropzoneState: Omit<DropzoneState, "getRootProps" | "getInputProps"> & {
      isDragging: boolean
    }
  ) => JSX.Element
}

export default function ChatDropzone({ children }: IChatDropzoneProps) {
  const {
    chatState,
    actions,
    dropzoneRef,
    rejectedFiles,
    setRejectedFiles,
  } = useContext<IChatContext>(ChatContext)

  const { id } = useParams()
  const tenant = useTenant()
  const { sendFile, loading } = useSendMessage(tenant, id)

  const [isDragging, setIsDragging] = useState(false)

  const closePreviewIfEmpty = (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      setIsDragging(false)
      actions?.setOpenPreview(false)
    }
  }

  const handleDropAcceptedFiles = (files: File[]) => {
    if (files.length) {
      const [accepted, rejected] = getAcceptedAndRejectedFiles(
        files,
        MAX_FILES - (chatState?.files?.length || 0),
        null,
      )

      // actions?.appendFiles(
      //   accepted.map((file, i) => {
      //     if (i === 0 && chatState?.files.length === 0) {
      //       return { file, text: chatState.message || "" }
      //     }
      //     return { file, text: "" }
      //   })
      // )

      const file = accepted[0]
      const fileType = ['image', 'video', 'audio'].find(
        it => file.type.indexOf(it) > -1
      ) || 'file'

      const data = formatFileMessageWithPayload(
        fileType as FileMessagePayloadType,
        {
          url: URL.createObjectURL(file),
          name: file.name,
          text: '',
          file,
        }
      )

      sendFile(data)
    }
  }

  const handleDropRejectedFiles = (filesRejection: FileRejection[]) => {

  }

  // const handleSubmitFiles = (files: IChatFile[]) => {
  //   for (const { file, text } of files) {
  //     const fileType = ['image', 'video', 'audio'].find(
  //       it => file.type.indexOf(it) > -1
  //     ) || 'file'
  //     const escapedText = escapeTextMessage(text)

  //     // TODO SEND MESSAGE

  //     actions?.clearFiles()
  //   }
  // }

  return (
    <Box
      className="h-100"
      sx={{ position: 'relative' }}
    >
      <Dropzone
        ref={dropzoneRef}
        noClick
        noKeyboard
        noDragEventsBubbling
        preventDropOnDocument
        // maxFiles={MAX_FILES - (chatState?.files?.length || 0)}
        maxSize={MAX_FILE_SIZE}
        onDragEnter={() => {
          setIsDragging(true)
          actions?.setOpenPreview(true)
        }}
        onDragLeave={() => {
          setIsDragging(false)
          !chatState?.files?.length && actions?.setOpenPreview(false)
        }}
        onDrop={closePreviewIfEmpty}
        onDropAccepted={handleDropAcceptedFiles}
        onDropRejected={handleDropRejectedFiles}
        maxFiles={1}
      >
        {({ getRootProps, getInputProps, ...otherProps }) => (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            className="h-100"
            {...getRootProps()}>
            <input {...getInputProps()} />
            {/* <Slide
                in={chatState?.openPreview}
                appear={isDragging}
                mountOnEnter
                direction="up"
                onExit={() => actions?.clearFiles()}
              >
                <Box
                  className="h-100 w-100 overflow-x-hidden"
                  sx={{
                    position: 'absolute',
                    zIndex: zIndexInput + 1,
                  }}
                >
                  <PreviewFiles
                    openNewFile={otherProps.open}
                    isDragActive={otherProps.isDragActive}
                    onSubmitFiles={handleSubmitFiles}
                  />
                </Box>
              </Slide> */}
            {children({ ...otherProps, isDragging })}
          </Grid>
        )}
      </Dropzone>
    </Box>
  )
}
