import { Typography } from "@mui/material"
import * as Styled from "./styled"
import { getMessageText } from "../../helper"
import Buttons from "../utils/Buttons"

export default (props) => {
  return (
    <Styled.Content>
      <Typography>{getMessageText(props)}</Typography>

      <Buttons buttons={props?.buttons} />
    </Styled.Content>
  )
}
