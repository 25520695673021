export const checkElementIsFocused = (elem?: Element) => {
  if (!elem) return false
  return document.activeElement === elem
}

export const checkAnyInputFocused = () => {
  if (document.activeElement) {
    return ["input", "textarea"].includes(
      document.activeElement.tagName.toLowerCase()
    )
  }
  return false
}

export const checkEventWithSpecialKey = (e: KeyboardEvent) =>
  ["ctrlKey", "metaKey", "shiftKey"].some((specialKey) => e[specialKey])

export const checkInputValueHasCharacter = (e: KeyboardEvent) =>
  e.key.length === 1 && /\S/.test(e.key)

export const focusInputIfCharacterPressed = (
  e: KeyboardEvent,
  input: HTMLInputElement
) => {
  if (!checkEventWithSpecialKey(e) && checkInputValueHasCharacter(e)) {
    input.focus()
  }
}
