import { createContext } from 'react'
import { IChatContext } from '@Commons/types/chat'

export const ChatContext = createContext<IChatContext>({
  id: undefined,
  setId: undefined,
  contact: undefined,
  chatState: undefined,
  actions: undefined,
  openMenu: undefined,
  setOpenMenu: undefined,
  messageInputRef: undefined,
  previewInputRef: undefined,
  dropzoneRef: undefined,
  setRejectedFiles: undefined,
  rejectedFiles: [],
  scrollRef: undefined,
  scrollToBottom: undefined,
})
