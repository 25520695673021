import { forwardRef, memo } from 'react'
import { FeatureProps } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/max'
import en from 'react-phone-number-input/locale/en'
import ptBR from 'react-phone-number-input/locale/pt-BR'
import { Divider, MenuItem, Stack } from '@mui/material'
import { useField } from 'formik'
import { CustomTextField as Input } from '../../Input'
import { Select } from '../Select'
import 'react-phone-number-input/style.css'
import { TInputProps } from '../../Input/types'
import withFormControl from '../FormControl'
import i18n from '@Commons/i18n'
import { getLanguage } from '@Commons/utils/locale'

const locales = {
  'en-US': en,
  'pt-BR': ptBR,
}
const locale = locales[getLanguage()] || locales['pt-BR']

const Container = props => <Stack direction="row" spacing={1} {...props} />

interface ISelectCountries {
  options: { value?: string; label?: string; divider?: boolean }[]
  iconComponent: React.FC<{ country?: string; countryName?: string }>
  onChange: (value?: string) => void
}

const SelectCountryFlag = memo(
  ({ options, iconComponent: Icon, ...rest }: ISelectCountries) => {
    const handleChange = e => {
      rest.onChange?.(e.target.value)
    }

    return (
      <Select {...rest} onChange={handleChange} fullWidth={false}>
        {options.map((opt, i) =>
          opt.divider ? (
            <Divider key={`select-flag-divider-${i}`} />
          ) : (
            <MenuItem key={opt.value} value={opt.value}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Icon country={opt.value} countryName={opt.label} />
                <span>{opt.label}</span>
              </Stack>
            </MenuItem>
          ),
        )}
      </Select>
    )
  },
)

const InputPhoneNumber = forwardRef<any, TInputProps>(
  ({ label, ...props }, ref) => (
    <Input {...props} inputProps={{ ...props.inputProps, ref }} />
  ),
)

type TInputPhone = Omit<FeatureProps<Omit<TInputProps, 'onChange'>>, 'ref'> & {
  onChange?: (e: any) => void
  defaultValue?: any
}

export const InputPhone = withFormControl<TInputPhone>(
  ({ label, ...props }: TInputPhone) => {
    const handleChange = (value?: string) => {
      props.onChange?.({
        currentTarget: {
          name: props.name || '',
          value: value || '',
        },
        target: {
          name: props.name || '',
          value: value || '',
        },
      })
    }

    return (
      <PhoneInput
        inputComponent={InputPhoneNumber}
        containerComponent={Container}
        countrySelectComponent={SelectCountryFlag}
        countrySelectProps={{
          variant: props.variant,
          fullWidth: props.fullWidth,
          error: props.error,
          margin: props.margin,
          sx: props.sx,
          required: props.required,
          label,
          ...props.countrySelectProps,
        }}
        {...props}
        onChange={handleChange}
        international
        labels={locale}
        countryOptionsOrder={['BR', 'US', '|', '...']}
      />
    )
  },
  {
    defaultCountry: 'BR',
    variant: 'default',
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    showFormControlLabel: false,
  },
)

export default function FieldInputPhone(props) {
  const [field, meta, helpers] = useField(props)

  return (
    <InputPhone
      {...props}
      {...field}
      {...(Boolean(meta.touched && meta.error) && {
        error: true,
        helperText: i18n.t(meta.error),
      })}
    />
  )
}
