import { Box, IconButton, lighten, Stack, SvgIcon, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as ArrowLeftIcon } from '@Assets/img/arrow-left.svg'
import { useContext } from 'react'
import { SessionContext } from '@Provider/index'

interface IHeader {
  goBack?: () => void
  label?: string
  children?: any
  sdk: any
}

export default function Header({
  goBack, label, children, sdk,
}: IHeader) {
  const { onClose } = useContext(SessionContext)

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        bgcolor: sdk.color,
        color: theme => theme.palette.getContrastText(sdk.color),
        p: 2,
      }}
    >
      {!!goBack && (
        <Box>
          <IconButton onClick={goBack}>
            <Box
              component={ArrowLeftIcon}
              sx={{
                width: 15,
                path: {
                  fill: theme => theme.palette.getContrastText(sdk.color),
                },
              }}
            />
          </IconButton>
        </Box>
      )}

      <Box sx={{ flexGrow: 1 }}>
        <Stack alignItems="center" justifyContent="center">
          {label ? (
            <Typography variant="h6" fontWeight={500}>{label}</Typography>
          ) : children}
        </Stack>
      </Box>

      {!sdk?.options.embedded && (
        <Box sx={{ '&&': { ml: '-40px' } }}>
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Stack>
  )
}
