import styled from 'styled-components'

export const Content = styled.div`
  padding: 5px 5px;
  border-radius: 1em;
  white-space: pre-line;
  z-index: 1;
  position: relative;
  word-break: break-all;
  margin-right: 15px;
  width: 100%;
`

export const TextWrapper = styled.div`
  padding: 5px;
  white-space: pre-line;
  flex-type: column;
`
