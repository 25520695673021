import React, { useContext, useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline'
import getTheme, { TenantTheme } from '@Commons/utils/theme'
import { SessionContext } from '@Provider/index'

export const ThemeModeContext = React.createContext({
  mode: '',
  toggleColorMode: () => { },
  setTenantTheme: (newTenantTheme: TenantTheme) => { },
})

type ThemeModeProviderProps = {
  children: any
  initialMode?: PaletteMode
}

const LOCALSTORAGE_KEY = '@hyperflow-chatweb-theme-mode'

const Provider: React.FC<ThemeModeProviderProps> = ({
  children,
  initialMode,
}) => {
  const { sdk } = useContext(SessionContext)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = useState<PaletteMode>(prefersDarkMode ? 'dark' : 'light')
  const [tenantTheme, setTenantTheme] = useState<TenantTheme>()
  const localMode = localStorage.getItem(
    LOCALSTORAGE_KEY,
  ) as PaletteMode | null

  useEffect(() => {
    if (!mode) {
      setMode(localMode || initialMode || (prefersDarkMode ? 'dark' : 'light'))
    }
  }, [])

  useEffect(() => {
    if (sdk) {
      setMode(sdk.theme)
      setTenantTheme({
        primaryColor: sdk.color,
      })
    }
  }, [sdk])

  const themeProviderValue = React.useMemo(
    () => ({
      mode,
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light'
          localStorage.setItem(LOCALSTORAGE_KEY, newMode)
          return newMode
        })
      },
      setTenantTheme: (newTenantTheme: TenantTheme) => {
        setTenantTheme(newTenantTheme)
      },
    }),
    [mode],
  )

  const theme = React.useMemo(
    () => getTheme(tenantTheme, mode),
    [mode, tenantTheme],
  )

  return (
    <ThemeModeContext.Provider value={themeProviderValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      </ThemeProvider>
    </ThemeModeContext.Provider>
  )
}

export default Provider
