import axios from 'axios'
import url from 'url'

const host = `${process.env.REACT_APP_API_URL}`
const origin = url.parse(
  document?.location?.ancestorOrigins?.[0] || document.referrer,
).host
const cancelTokens = {}
sessionStorage.setItem('sessionStartAt', new Date().toISOString())

const request = params => {
  if (params.cancel) {
    cancelTokens[params.cancel] && cancelTokens[params.cancel].cancel()
    cancelTokens[params.cancel] = axios.CancelToken.source()
    params.cancelToken = cancelTokens[params.cancel].token
  }

  return axios({
    baseURL: host,
    headers: {
      'Content-Type': 'application/json',
      // 'n-origin': sessionStorage.getItem('token')
      //   ? `${origin}|${sessionStorage.getItem('token')}`
      //   : origin,
      // sessionStartAt: sessionStorage.getItem('sessionStartAt'),
      sender:
        localStorage.getItem('fingerprint') || (window as any).fingerprint,
    },
    ...params,
    params: {
      ...params.params,
      token: sessionStorage.getItem('token'),
    },
  })
}

export default {
  getConversations: () =>
    request({
      url: `/conversations`,
      method: 'GET',
      cancel: 'getConversations',
    }),
  getMessages: (chatId, lastMessage = '') =>
    request({
      url: `/messages/${chatId}`,
      method: 'GET',
      cancel: 'getMessages',
    }),
  initializeUser: (token = '') =>
    request({
      url: `/user`,
      method: 'POST',
      data: {
        token,
      },
      cancel: 'initializeUser',
    }),
  send: data =>
    request({
      url: '/websdk',
      method: 'POST',
      data,
    }),
}
