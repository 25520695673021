import { SessionContext } from '@Provider/index'
import { ISessionContext } from '@Provider/interfaces/sessionContext.interface'
import { v4 as uuidv4 } from 'uuid'
import {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from 'react'
import { useWebSocket } from './useWebSocket'
import Cookies from 'js-cookie'
import { ReadyState } from './useWebSocket/constants'
import i18n from '@Commons/i18n'

interface IUseSocketEventOptions {
  onReconnect?: () => void
}

interface IEventsListener {
  (
    event: string,
    listener: (callback, ack?) => void,
    options?: IUseSocketEventOptions,
  ): [string, any | undefined]
}

export const useWebSocketEvent: any = (
  events?: Record<string, (callback, ack?) => void>,
  options?: IUseSocketEventOptions,
) => {
  const listConfirmations = useRef<any>({})
  const [rooms, setRooms] = useState<any>([])
  const [resync, setResync] = useState(false)

  const { sdk } = useContext(SessionContext)

  const url = useCallback(() => {
    return process.env?.REACT_APP_IS_OFFLINE
      ? `${process.env.REACT_APP_WS_API}/?token=${sdk.token}&sender=${
          localStorage.getItem('fingerprint') || (window as any).fingerprint
        }`
      : `wss://wswebsdk.${process.env.REACT_APP_DOMAIN_APIS}/?token=${
          sdk.token
        }&sender=${
          localStorage.getItem('fingerprint') || (window as any).fingerprint
        }`
  }, [])

  const { close, sendJsonMessage, readyState } = useWebSocket(url, {
    share: true,
    shouldReconnect: () => true,
    reconnectInterval: 1000,
    reconnectAttempts: 86400,
    onMessage: (event: any) => {
      const data = JSON.parse(event.data)

      if (events?.[data.action]) {
        return events[data.action](data.data)
      }

      if (data.ack) {
        const [_, ID_TRANSACTION] = data.ack.split('|')
        if (listConfirmations.current[ID_TRANSACTION]) {
          listConfirmations.current[ID_TRANSACTION].res(data.data)
          delete listConfirmations.current[ID_TRANSACTION]
        }
      }
    },
  })

  useEffect(() => {
    if (readyState === ReadyState.CLOSED) {
      setResync(true)
    }

    if (resync && readyState === ReadyState.OPEN) {
      setResync(false)
      for (const room of rooms) {
        resyncRoom(room)
      }
      options?.onReconnect?.()
    }
  }, [readyState])

  const resyncRoom = room => {
    sendJsonMessage({
      action: 'joinRoom',
      data: room,
    })
  }

  const joinRoom = (room, notify?: boolean) => {
    if (rooms.includes(room)) {
      return
    }

    setRooms(rooms => rooms.filter(it => it !== room).concat(room))

    sendJsonMessage({
      action: 'joinRoom',
      data: room,
      notify,
    })
  }

  const leaveRoom = (room, notify?: boolean) => {
    setRooms(rooms => rooms.filter(it => it !== room))
    sendJsonMessage({
      action: 'leaveRoom',
      data: room,
      notify,
    })
  }

  const sendMessageWithAck = async (action, data) => {
    const ID_TRANSACTION = uuidv4()
    return new Promise((res, rej) => {
      listConfirmations.current[ID_TRANSACTION] = { res, rej }
      sendJsonMessage({
        action,
        data,
        ack: ID_TRANSACTION,
      })
      setTimeout(() => {
        if (listConfirmations.current[ID_TRANSACTION]) {
          rej(i18n.t('Failed to send'))
        }
      }, 3000)
    })
  }

  const sendMessage = async (action, data) => {
    sendJsonMessage({
      action,
      data,
    })
  }

  return {
    readyState,
    sendJsonMessage,
    joinRoom,
    leaveRoom,
    sendMessage,
    sendMessageWithAck,
    close,
  }
}

export default useWebSocketEvent
