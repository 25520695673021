import { useEffect, useMemo, useState } from "react"
import { saveAs } from "file-saver"
import AttachIcon from "@mui/icons-material/AttachFile"
import DownloadIcon from "@mui/icons-material/ArrowDownward"
import SearchIcon from "@mui/icons-material/Search"
import { CircularProgress, Grid, IconButton } from "@mui/material"
import HandleType from "./types"
import FileStyle from "./types/style"
import * as Styled from "./styled"
import { getFileData } from "./helper"

export default function FileMessage({ message, onClick }) {
  const fileData = useMemo(() => getFileData(message), [message])

  const types = {
    image: {
      icon: require("@Assets/img/photo.png"),
      label: "Foto",
    },
    audio: {
      icon: require("@Assets/img/headphones.png"),
      label: "Áudio",
    },
    video: {
      icon: require("@Assets/img/video.png"),
      label: "Vídeo",
    },
    file: {
      icon: require("@Assets/img/document.png"),
      label: "Documento",
    },
  }

  return (
    <Styled.Content key={message.id}>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <img
            src={types[fileData.fileType].icon}
            style={{ width: 16, height: 16, marginTop: 5 }}
          />
        </Grid>
        <Grid item xs>
          {fileData?.text || types[fileData.fileType].label}
        </Grid>
        {fileData.fileType === "image" && (
          <Grid item>
            <FileStyle.Image src={fileData.fileUrl} style={{ width: 60 }} />
          </Grid>
        )}
      </Grid>
    </Styled.Content>
  )
}
