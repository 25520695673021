import styled from 'styled-components'
import iconButton from '@mui/material/IconButton'

export default {
  SpeedButton: styled(iconButton)`
    && {
      box-shadow: none;
      height: 34px;
      width: 35px;
      font-size: 13px;
      font-weight: bold;
      color: #000;
    }
  `,
  Audio: styled.audio`
    ${({ theme }) => theme.breakpoints.only('xs')} {
      width: 100%;
      min-width: 170px !important;
      height: 30px;
    }
  `,
  Image: styled.img`
    vertical-align: middle;
    max-width: 250px;
    object-fit: cover;
    border-radius: 1em;
  `,
}
