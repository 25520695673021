import Text from "./text"
import api from "./api"
import contact from "./contact"
import postback from "./postback"
import button from "./button"
import generic from "./generic"
import image from "./image"
import sticker from "./sticker"
import audio from "./audio"
import video from "./video"
import file from "./file"
import List from "./list"
import deleted from "./deleted"
import hsm from "./hsm"
import system from './system'

export default {
  text: Text,
  audio,
  video,
  generic,
  button,
  image,
  sticker,
  list: (props) => <List {...props} />,
  file,
  deleted,
  postback,
  system,
  api,
  contact,
  hsm,
}
