import React, { memo } from 'react'
import { Field } from 'formik'
import { areEqual, getFieldProps } from './utils'
import { TInputProps } from '@Components/Input/types'

type FieldProps = TInputProps & {
  [key: string]: any
}

export function withField<T = FieldProps>(
  Input: (props: any) => JSX.Element | React.ReactElement<any, any> | null,
) {
  const component = memo(props => {
    const fieldProps = getFieldProps(props)
    return <Input {...fieldProps} />
  }, areEqual)
  return (props: T) => <Field {...props} component={component} />
}

export default withField
