

const isCellphone = (value?: string) => {
  return true
  // if (!value) {
  //   return false
  // }
  // const size = `${value}`.length
  // if (size < 11) {
  //   return false
  // }
  // const number = parsePhoneNumberFromString(value)
  // return (number?.isValid?.() && number?.getType?.() === 'MOBILE') || false
}

function cellphone() {
  return this.test('cellphone', 'cellphoneInvalid', (value) =>
    isCellphone(value),
  )
}

export { isCellphone, cellphone }
