import useSendMessage from "@hooks/useSendMessage"
import { Stack } from "@mui/material"
import * as Styled from "./styled"
import { useParams } from 'react-router-dom'
import useTenant from "@hooks/useTenant"
import { useContext } from "react"
import { SessionContext } from "@Provider/index"

export default ({ buttons }) => {
  const { setIframe } = useContext(SessionContext)
  if (!buttons?.length) return null

  const { id } = useParams()
  const tenant = useTenant()
  const { sendButton, loading } = useSendMessage(tenant, id)

  const handleButton = (btn) => {
    if (btn.type === 'url') {
      if (btn.link === 'internal') {
        setIframe(btn.url)
      } else if (btn.link === 'popup') {
        window.open(
          btn.url, "popup", 'width=600,height=600'
        )
      }
      else {
        window.open(
          btn.url, "_blank"
        )
      }

      return
    }

    sendButton({
      title: btn.label,
      payload: btn.payload
    })
  }

  return (
    <Stack spacing={1} mt={1}>
      {!!buttons?.length && buttons.map((button, index) => (
        <Styled.Button onClick={() => handleButton(button)}>
          {button.label}
        </Styled.Button>
      ))}
    </Stack>
  )
}

