import { useMemo } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import DefaultTextField from './Default/styles'
import { AllVariants, NewVariants, TInputComponent, TInputProps } from './types'
import i18n from '@Commons/i18n'
import withField from '@Components/Form/Field'

export function isCustomVariant(variant?: string): variant is NewVariants {
  if (!variant) return false
  return ['default', 'light', 'borderPrimaryLight'].includes(variant)
}

const variantsTextField: Record<AllVariants, TInputComponent> = {
  default: (props: TextFieldProps) => (
    <DefaultTextField
      {...props}
      variant="standard"
      InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
    />
  ),
  standard: TextField,
  filled: TextField,
  outlined: TextField,
}

export const CustomTextField = (props: TInputProps) => {
  const InputComponent: TInputComponent = useMemo(() => {
    return variantsTextField[props.variant || 'default']
  }, [props.variant])
  const trueVariant = isCustomVariant(props.variant)
    ? 'standard'
    : props.variant

  return (
    <InputComponent
      {...props}
      variant={trueVariant}
      helperText={props.helperText ? i18n.t(props.helperText) : ''}
    />
  )
}
CustomTextField.defaultProps = {
  variant: 'default',
}

export default withField(CustomTextField)
