import In from "./In"
import Out from "./Out"
import Info from "./Info"

export default (props) => {
  const types = {
    in: props => <In {...props} />,
    out: props => <Out {...props} />,
    info: props => <Info {...props} />,
    middle: props => <Info {...props} />,
  }

  return types[props.message.direction]?.(props) || null
}
