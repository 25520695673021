import {
  EActionKind,
  IChatState,
  TAction,
  TState,
} from '@Commons/types/chatReducer'

const initialChatState: IChatState = {
  chatId: undefined,
  openPreview: false,
  message: '',
  files: [],
  selectedFileIndex: 0,
  replyMessage: undefined,
  loadingExport: false,
  messages: [],
}

export default function reducer(state: TState, action: TAction) {
  const chatData = state[action.payload.chatId]

  switch (action.type) {
    case EActionKind.resetChat:
      return {
        ...state,
        [action.payload.chatId]: initialChatState,
      }
    case EActionKind.setMessage:
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          message: action.payload.message,
        },
      }
    case EActionKind.appendMessage:
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          message: chatData.message + action.payload.message,
        },
      }
    case EActionKind.appendFiles:
      let appendedFiles = [...chatData.files, ...action.payload.files]
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          files: appendedFiles,
          selectedFileIndex: appendedFiles.length - 1,
          openPreview: true,
        },
      }
    case EActionKind.removeFile:
      const remainingFiles = chatData.files.splice(action.payload.index, 1)
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          files: remainingFiles,
          ...(chatData.selectedFileIndex >= remainingFiles.length && {
            selectedFileINdex: remainingFiles.length - 1,
          }),
        },
      }
    case EActionKind.clearFiles:
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          files: [],
          selectedFileIndex: 0,
          openPreview: false,
        },
      }
    case EActionKind.setFileIndex:
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          selectedFileIndex: action.payload.index,
        },
      }
    case EActionKind.setReplyMessage:
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          replyMessage: action.payload.message,
        },
      }
    case EActionKind.setOpenPreview:
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          openPreview: action.payload.open,
        },
      }
    case EActionKind.setFileText:
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          files: chatData.files.map((chatFile, i) => {
            if (i === chatData.selectedFileIndex) {
              return { ...chatFile, text: action.payload.text }
            }
            return chatFile
          }),
        },
      }
    case EActionKind.setLoadingExport:
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          loadingExport: action.payload.loading,
        },
      }
    case EActionKind.setMessages:
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          messages: action.payload.messages,
        },
      }
    case EActionKind.appendMessages:
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          messages: [...(chatData?.messages || []), ...action.payload.messages],
        },
      }
    case EActionKind.setMessageByRef:
      return {
        ...state,
        [action.payload.chatId]: {
          ...chatData,
          messages: chatData.messages.map(message => {
            if (message.ref === action.payload.ref) {
              return { ...message, ...action.payload.message }
            }
            return message
          }),
        },
      }
    default:
      console.log('Unknown action type', action)
      return state
  }
}
