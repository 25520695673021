import { differenceInMilliseconds } from 'date-fns'

export const zIndexInput = 100

export const checkWhatsappUnavailable = (chat) => {
  let sessionValid
  let timeFromLastMessage = 99
  const A_DAY = 1000 * 60 * 60 * 24

  if (chat.sessionCreatedAt) {
    sessionValid =
      differenceInMilliseconds(new Date(), new Date(chat.sessionCreatedAt)) >
      A_DAY
  }

  if (chat.lastMessage) {
    timeFromLastMessage = differenceInMilliseconds(
      new Date(),
      new Date(chat.lastMessage.createdAt),
    )
  }

  return (
    !sessionValid &&
    timeFromLastMessage >= A_DAY &&
    chat.channel === 'whatsapp' &&
    !['chatAPI', 'zAPI', 'wavy'].includes(chat.variant)
  )
}

export const escapeTextMessage = (text: string): string => {
  if (!text.trim().length) return text
  const div = document.createElement('div')
  const nodeText = document.createTextNode(text.trim())
  div.appendChild(nodeText)
  return div.textContent || ''
}
