import { alpha, styled, Theme } from '@mui/material'
import MuiTextField from '@mui/material/TextField'

export const getLabelSpacingStyle = (theme: Theme, margin) => ({
  marginTop: margin === 'none' ? theme.spacing(2) : theme.spacing(3),
})

export const getWrapperStyle = (theme: Theme, { label, margin }) => ({
  borderRadius: theme.spacing(1),
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create(
    ['border-color', 'background-color', 'box-shadow'],
    {
      duration: theme.transitions.duration.shorter,
    },
  ),
  fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
  padding: '5px 8px',
  marginTop:
    margin === 'none' ? (label ? theme.spacing(2) : 0) : theme.spacing(3),
})

export const getInputStyle = (theme: Theme) => ({
  fontSize: '1rem',
  paddingTop: 4,
  paddingBottom: 4,
  paddingLeft: 4,
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '&.MuiInputBase-inputSizeSmall': {
    fontSize: '0.9rem',
    paddingBottom: 3,
    paddingTop: 2,
  },
})

export const getWrapperFocusedStyle = (theme: Theme) => ({
  boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
  borderColor: theme.palette.primary.main,
})

export const getWrapperErrorFocusedStyle = (theme: Theme) => ({
  boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
  borderColor: theme.palette.error.main,
})

export const getWrapperErrorStyle = (theme: Theme) => ({
  borderColor: theme.palette.error.main,
})

export const getAllInputStyles = (theme: Theme, { label, margin }) => ({
  '&.MuiInputBase-root': getWrapperStyle(theme, { label, margin }),
  '& .MuiInputBase-input': getInputStyle(theme),
  '&.Mui-focused:not(.Mui-error)': getWrapperFocusedStyle(theme),
  '&.Mui-error:not(.Mui-focused)': getWrapperErrorStyle(theme),
  '&.Mui-focused.Mui-error': getWrapperErrorFocusedStyle(theme),
})

const StyledTextField = styled(MuiTextField)(({ theme, label, margin }) => {
  return {
    '& .MuiInput-root': {
      '&:before': {
        content: '""',
        borderBottom: 0,
      },
      '&:after': {
        content: '""',
        borderBottom: 0,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 0,
      },
      ...getAllInputStyles(theme, { label, margin }),
    },
  }
})

export default StyledTextField
