import { IUseEventListeners } from '@Commons/types/chatEventListeners'
import { checkAnyInputFocused, focusInputIfCharacterPressed } from '../helper'

// Auto-focus input (preview or message inputs)
// if a character is pressed without any input active
function autoFocusInputIfNoneActive(
  e: KeyboardEvent,
  data: IUseEventListeners,
) {
  const { chatState, messageInputRef, previewInputRef } = data

  if (!chatState) return
  if (!messageInputRef.current && !previewInputRef.current) return
  if (checkAnyInputFocused()) return

  if (chatState?.openPreview) {
    if (previewInputRef.current) {
      focusInputIfCharacterPressed(e, previewInputRef.current)
    }
  } else {
    if (messageInputRef.current) {
      focusInputIfCharacterPressed(e, messageInputRef.current)
    }
  }
}

export default function onDocumentKeyDown(
  e: KeyboardEvent,
  data: IUseEventListeners,
) {
  autoFocusInputIfNoneActive(e, data)
}
