import { useEventListener } from '@hooks/useEventListener'
import { IUseEventListeners } from '@Commons/types/chatEventListeners'
import onDocumentKeyDown from './events/keyDown'
import onDocumentKeyUp from './events/keyUp'
import onDocumentPaste from './events/paste'

export default function useEventListeners(data: IUseEventListeners) {
  useEventListener<KeyboardEvent>(
    document,
    'keydown',
    e => onDocumentKeyDown(e, data),
    [data.chatState],
  )

  useEventListener<KeyboardEvent>(
    document,
    'keyup',
    e => onDocumentKeyUp(e, data),
    [data.chatState, data.actions],
  )

  useEventListener<ClipboardEvent>(
    document,
    'paste',
    e => onDocumentPaste(e, data),
    [data.chatState, data.actions],
  )
}
