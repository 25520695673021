import { action, thunk } from 'easy-peasy'
import api from '@resources/api'
import MessageSent from '@Assets/sound/message-sent.mp3'
import { Howl } from 'howler'

const initialState = {
  loadingConversations: false,
  conversations: [],
  messages: {},
  initialized: false,
  unreadMessages: false,
}

const actions = {
  setConversations: action((state: any, payload) => {
    state.conversations = payload
  }),
  setInitialized: action((state: any, payload) => {
    state.initialized = payload
  }),
  setLoading: action((state, payload) => {
    state[`loading${payload.target}`] = payload.value
  }),
  addMessage: action((state: any, payload) => {
    const { id, message } = payload
    state.messages[id] = (state.messages[id] || []).concat(message)
  }),
  set_unread_messages: action((state: any, payload) => {
    state.unreadMessages = payload
  }),
  update_message: action((state: any, payload) => {
    const { message } = payload
    const id = message.conversation
    if (state.messages[id]) {
      const messages = state.messages[id] || []
      const foundMessage = messages.find(
        msg =>
          (msg.id && msg.id === message.id) ||
          (!msg.id && msg.ref && msg.ref === message.ref),
      )
      foundMessage && Object.assign(foundMessage, message)
      state.messages[id] = [...messages]
    }
  }),
  update_conversation: action((state: any, payload) => {
    const { message } = payload
    const id = message.conversation
    const conversations = state.conversations
    const foundConversation = conversations.find(cvs => cvs.id === id)
    if (foundConversation) {
      Object.assign(foundConversation, {
        lastMessage: message,
        updatedAt: new Date(),
      })
    }
    state.conversations = [...conversations]
  }),
  set_conversation_messages: action((state: any, payload: any) => {
    const { id, sync, messages } = payload

    if (
      sync &&
      state.messages[id] &&
      state.messages[id].length &&
      state.messages[id].length !== messages.length
    ) {
      var sound = new Howl({
        src: [MessageSent],
      })

      sound.play()
    }
    state.messages[id] = messages
  }),
  message_status: thunk(async (actions: any, payload: any, { getState }) => {
    const { message } = payload
    actions.update_message({
      id: message.conversation,
      message,
    })
  }),
  new_message: thunk(async (actions: any, payload: any, { getState }) => {
    const { message, id, newConversation } = payload
    const { messages, conversations }: any = getState()
    if (newConversation) {
      actions.set_conversation_messages({
        id: message.conversation,
        messages: [message],
      })
    } else if (!messages[message.conversation]) {
      actions.sync_messages(id)
    } else if (message.direction === 'in') {
      window.parent.postMessage(
        {
          action: 'websdk|newMessage',
          message: message.payload,
        },
        '*',
      )

      actions.addMessage({
        id: message.conversation,
        message,
      })

      var sound = new Howl({
        src: [MessageSent],
      })

      sound.play()
    } else {
      const foundMessage = messages[message.conversation].find(
        msg =>
          (msg.id && msg.id === message.id) ||
          (!msg.id && msg.ref && msg.ref === message.ref),
      )

      if (foundMessage) {
        actions.update_message({
          id: message.conversation,
          message,
        })
      } else {
        actions.addMessage({
          id: message.conversation,
          message,
        })
      }
    }
    actions.sync_conversations()
  }),
  sync_conversations: thunk(
    async (actions: any, payload: any, { getState }) => {
      actions.setLoading({ target: 'Conversations', value: true })
      const { data } = await api.getConversations()
      actions.setConversations(data.conversations)
      actions.setInitialized(true)
      actions.setLoading({ target: 'Conversations', value: false })
    },
  ),
  sync_messages: thunk(async (actions: any, id: any, { getState }) => {
    if (id === 'newConversation') {
      return
    }

    actions.setLoading({ target: `Messages${id}`, value: true })
    const { data } = await api.getMessages(id)
    // actions.setConversations(data.conversations)
    actions.set_conversation_messages({
      id,
      messages: data.messages,
      sync: true,
    })
    actions.setLoading({ target: `Messages${id}`, value: false })
  }),
}

export const chat = {
  ...initialState,
  ...actions,
}

export default chat
