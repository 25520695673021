import { Grid } from "@mui/material"
import * as Styled from "./styled"

export default (message) => {
  return (
    <Styled.Content key={message.id}>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Styled.Image src={message.url} />
        </Grid>
      </Grid>
    </Styled.Content>
  )
}
