import { Grid, Typography } from "@mui/material"
import { formatDate } from "@Commons/utils/date"
import * as Styled from "./styled"
import { memo, useContext } from "react"
import { SessionContext } from "@Provider/index"

function Info({ children, user, message }) {
  const { sdk } = useContext(SessionContext)
  return (
    <Styled.Container
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item style={{ maxWidth: "80%" }}>
        {children}
      </Grid>
      <Grid item>
        <div>
          <Typography variant="caption" fontWeight={500}>
            {" "}
            {message.meta || sdk.title || <span>Bot</span>}
            <span>&nbsp;-</span>{" "}
          </Typography>
          <Typography variant="caption">
            {" "}
            {formatDate(message.createdAt, "dd/MM - HH[h]mm")}{" "}
          </Typography>
        </div>
      </Grid>
    </Styled.Container>
  )
}

export default memo(Info)
