import { Typography } from "@mui/material"
import i18n from "@Commons/i18n"
import * as Styled from "./styled"

const infoText = {
  INIT_CHAT: i18n.t("The chat has been transfered"),
  FINISH_CHAT: i18n.t("The chat has been finished"),
}

const handleType = {
  INIT_CHAT: (payload) => (
    <>
      {i18n.t("The chat has been transfered")}
      <Typography fontWeight={500} sx={{ pl: 1 }}>
        &nbsp;({payload.department})
      </Typography>
      {payload.observations && (
        <Styled.Observations>
          <legend>{i18n.t("Observations")}</legend>
          {payload.observations}
        </Styled.Observations>
      )}
    </>
  ),
  CHAT_TRANSFERED: (payload) => (
    <>
      <span>
        {payload.agent ? (
          <>
            <Typography fontWeight={500}>{payload.agent}</Typography>
            <Typography>&nbsp;{i18n.t("joined chat")}</Typography>
          </>
        ) : (
          i18n.t("The chat has been transfered")
        )}
      </span>
      {payload.department && (
        <Typography fontWeight={500} sx={{ pl: 1 }}>
          &nbsp;({payload.department})
        </Typography>
      )}
      {/* {payload.agent && (
        <Typography sx={{ pl: 1 }}>{` - ${payload.agent}`}</Typography>
      )} */}
      {payload.observations && (
        <Styled.Observations>
          <legend>{i18n.t("Observations")}</legend>
          {payload.observations}
        </Styled.Observations>
      )}
    </>
  ),
  ACTIVE_CHAT: (payload) => (
    <>
      {i18n.t("The chat has been started")}
      <Typography fontWeight={500} sx={{ pl: 1 }} component="span">
        &nbsp;({payload.department})
      </Typography>
      {payload.protocol && (
        <Styled.Protocol>
          <Typography fontWeight={500} component="span">
            #{payload.protocol}
          </Typography>
        </Styled.Protocol>
      )}
    </>
  ),
  INIT_PROTOCOL: (payload) => (
    <>
      {i18n.t("Attendance started")}
      <Typography fontWeight={500} sx={{ pl: 1 }} component="span">
        &nbsp;({payload.department})
      </Typography>
      {payload.protocol && (
        <Styled.Protocol>
          <Typography fontWeight={500}>#{payload.protocol}</Typography>
        </Styled.Protocol>
      )}
    </>
  ),
  FINISH_CHAT: (payload) => (
    <>
      {i18n.t("The chat has been finished")}
      {payload.protocol && (
        <Styled.Protocol>
          <Typography fontWeight={500} component="span">
            #{payload.protocol}
          </Typography>
        </Styled.Protocol>
      )}
    </>
  ),
}

export default (message) => {
  return (
    <Styled.Content key={message.timestamp}>
      {handleType[message.payload?.type]?.(message.payload) ||
        infoText[message.payload.type]}
    </Styled.Content>
  )
}
