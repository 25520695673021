import styled, { css } from 'styled-components'
import ButtonBase from '@mui/material/ButtonBase'

export const Container = styled.div`
  width: 100%;
  padding: 5px;
`

export const Reply = styled(ButtonBase)`
  margin: 0 !important;
  background: transparent !important;
  font-weight: bold;
  padding: 8px 12px !important;
  border: 1px solid #eaeaea !important;
  border-radius: 0.4em !important;
  border-color: ${props => props.color} !important;
  color: ${props => (props.theme === 'dark' ? '#FFF' : props.color)} !important;
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 4em !important;
    `}
`
