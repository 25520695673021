import { useContext } from "react"
import { Box, ClickAwayListener, Grid, IconButton, InputBase, Stack, Theme, useMediaQuery } from "@mui/material"
import { AiOutlineSend } from 'react-icons/ai'
import { ChatContext } from "@Components/Chat/context"
import { escapeTextMessage, zIndexInput } from "@Components/Chat/helper"
import SlideMenus from "./SlideMenus"
import { ATTACHMENT_OPTIONS } from "./helper"
import FooterLeftOptions from "./LeftOptions"
import i18n from "@Commons/i18n"
import { formatMessageWithPayload } from "@Components/Chat/formatMessage"
import useSendMessage from "@hooks/useSendMessage"
import { IChatContext } from "@Commons/types/chat"
import { SessionContext } from "@Provider/index"
import { useNavigate, useParams } from "react-router-dom"
import { useStoreState } from 'easy-peasy'
import { Typography } from "@mui/material"
import { Button } from "@mui/material"

export default function Footer() {
  const navigate = useNavigate()
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const { sdk } = useContext(SessionContext)
  const { chatState, messageInputRef, actions, setOpenMenu } = useContext<IChatContext>(ChatContext)
  const { message = '' } = chatState || {}

  const { id } = useParams()

  const { sendMessage, loading } = useSendMessage(sdk.tenant, id)

  const allowedOptions = [null, undefined, true].includes(sdk.allowUpload)
    ? ATTACHMENT_OPTIONS
    : []

  const handleSubmitText = e => {
    e.preventDefault()

    if (!chatState?.message) return

    const payloadBody = {}

    if (sdk.initialPayload && sdk.options?.queryParams && id === "newConversation") {
      for (const [key, value] of Object.entries(sdk.options?.queryParams)) {
        payloadBody[key] = value;
      }
    }

    const escapedText = escapeTextMessage(chatState.message)
    const data = formatMessageWithPayload<'text'>(
      'text',
      {
        text: escapedText,
        payload: payloadBody
      },
      chatState.replyMessage
    )

    setOpenMenu?.(null)
    actions?.setMessage('')
    actions?.clearFiles()
    actions?.setReplyMessage(null)

    sendMessage<'text'>(data).then((response) => {
      // scrollToBottom?.()

      if (chatState?.chatId === 'newConversation') {
        navigate(`/chats/${response.conversation.id}`)
      }
    })
  }

  const submitOnEnter = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      handleSubmitText(e)
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setOpenMenu?.(null)}>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{
          position: 'relative',
          zIndex: zIndexInput - 2,
        }}>
          <SlideMenus />
        </Box>

        <form
          id="form-message-input"
          autoComplete="off"
          onSubmit={handleSubmitText}
        >
          <Box
            sx={{
              position: 'relative',
              zIndex: zIndexInput,
              p: 1,
              pl: 2,
              pb: 0.5,
              borderTop: '1px solid',
              borderColor: 'divider',
            }}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs>
                <InputBase
                  inputProps={{
                    ref: messageInputRef,
                  }}
                  value={message}
                  onChange={e => actions?.setMessage(e.target.value)}
                  placeholder={`${i18n.t('Type a message')}...`}
                  multiline
                  maxRows={4}
                  fullWidth
                  autoFocus={isMdUp}
                  onKeyPress={submitOnEnter}
                  margin="none"
                />
              </Grid>

              <Grid item>
                <FooterLeftOptions
                  options={allowedOptions}
                />
              </Grid>

              <Grid item>
                <IconButton type="submit">
                  <AiOutlineSend />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </form >
      </Box >
    </ClickAwayListener>
  )
}
