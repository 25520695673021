import { memo } from "react"
import DefaultLayout from "./Layout"

interface IAppRoute {
  component: any
  layout: any
}

const AppRoute = ({
  component: Component,
  layout: Layout,
  ...otherProps
}: IAppRoute) => {
  return (
    <Layout {...otherProps}>
      <Component {...otherProps} />
    </Layout>
  )
}

AppRoute.defaultProps = {
  layout: DefaultLayout,
}

export default memo(AppRoute)
