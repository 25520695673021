import React from "react"
import { format } from "@flasd/whatsapp-formatting"
import * as Styled from "./styled"
import Buttons from "../utils/Buttons"

export default (message) => (
  <>
    <Styled.Content key={message.timestamp}>
      <span
        dangerouslySetInnerHTML={{
          __html: format(message.payload?.text || ''),
        }}
      />
    </Styled.Content>
    <Buttons buttons={message.payload?.buttons} />
  </>
)
