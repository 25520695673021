export default {
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_AWS_REGION,
    mandatorySignIn: false,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_STORAGE_BUCKET, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_AWS_REGION, //OPTIONAL -  Amazon service region
    },
  },
}
