import { MouseEvent, useState } from 'react'
import { Grid, IconButton, Menu, MenuItem } from '@mui/material'
import { AiOutlinePaperClip } from 'react-icons/ai'
import { IAttachmentOption } from '../../helper'

interface IButtonAttachments {
  options: IAttachmentOption[]
  onChooseOption: (e: MouseEvent, option: IAttachmentOption) => void
}

const ButtonAttachments = ({ options, onChooseOption }: IButtonAttachments) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    if (options.length === 1) {
      handleChooseOption(event, options[0])
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChooseOption = (e, option) => {
    onChooseOption(e, option)
    handleClose()
  }

  return options.length ? (<>
    <IconButton onClick={handleClick}>
      <AiOutlinePaperClip />
    </IconButton>

    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}>
      {options.map(opt => (
        <MenuItem key={`attachment-${opt.label}`} onClick={(e) => handleChooseOption(e, opt)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item sx={{ display: 'flex' }}>{opt.icon}</Grid>
            <Grid item>{opt.label}</Grid>
          </Grid>
        </MenuItem>
      ))}
    </Menu>
  </>) : null
}

export default ButtonAttachments
