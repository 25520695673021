import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

const isPhone = (value) => {
  if (!value) {
    return false
  }

  const size = `${value}`.replace(/\D/g, '').length
  if (size < 10) {
    return false
  }

  const number = parsePhoneNumberFromString(value)

  return (number?.isValid?.() && !!number?.getType?.()) || false
}

const isPossiblePhone = (value) => {
  if (!value) {
    return false
  }

  const size = `${value}`.replace(/\D/g, '').length
  if (size < 10) {
    return false
  }

  const number = parsePhoneNumberFromString(value, 'BR')

  return number?.isPossible?.() || false
}

function phone() {
  return this.test('phone', 'phoneInvalid', (value) => isPhone(value))
}

function possiblePhone() {
  return this.test('possiblehone', 'phoneInvalid', (value) =>
    isPossiblePhone(value),
  )
}

export { isPhone, phone, possiblePhone }
