/* eslint-disable complexity */
const hasShallowDiff = (x: Record<string, any>, y: Record<string, any>) => {
  const keys = Object.keys(x)

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]

    if (x[key] !== y[key]) {
      return false
    }
  }

  return true
}

const swap = (list, startIndex, finalIndex) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(finalIndex, 0, removed)
  return result
}

export { hasShallowDiff, swap }
