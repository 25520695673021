import {
  Suspense,
  memo,
  useEffect,
  useState,
  useMemo,
  useRef,
  useContext,
} from 'react'
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import routes from '@routes'
import AppRoute from './AppRoute'
import { LoadingContainer } from './style'
import { Stack } from '@mui/material'
import { useStoreActions, useStoreState } from 'easy-peasy'
import useWebSocketEvent from '@hooks/useWebSocketEvent'
import { v4 as uuidv4 } from 'uuid'
import { SessionContext } from '@Provider/index'
import { ISessionContext } from '@Provider/interfaces/sessionContext.interface'

const Main = () => {
  const { sdk } = useContext(SessionContext)
  const navigate = useNavigate()
  const location = useLocation()
  const self = useRef<{ initialized: boolean }>({ initialized: false })
  const [hooks, setHooks] = useState<any>([])
  const { set_unread_messages } = useStoreActions(({ chat }: any) => ({
    set_unread_messages: chat.set_unread_messages,
  }))

  const { loading, initialized, conversations } = useStoreState(
    ({ chat }: any) => ({
      loading: chat.loadingConversations,
      conversations: chat.conversations,
      initialized: chat.initialized,
    }),
  )

  const redirect = useMemo(() => sessionStorage.getItem('pathname'), [])

  const handlePostMessageEvent = async event => {
    ;['initFlow'].includes(event.data.event) &&
      setHooks(hooks => hooks.concat(event.data))
  }

  useEffect(() => {
    window.addEventListener('message', handlePostMessageEvent)
    return () => {
      window.removeEventListener('message', handlePostMessageEvent)
    }
  }, [])

  const handleNewMessage = async data => {
    if (location.pathname === '/') {
      set_unread_messages(true)
    }
  }

  const { sendMessageWithAck } = useWebSocketEvent({
    new_message: handleNewMessage,
  })

  const handleEvent = async data => {
    const id = conversations?.[0]?.id || 'newConversation'
    const result = await sendMessageWithAck('message', {
      id,
      message: {
        ref: uuidv4(),
        type: 'postback',
        silent: true,
        direction: 'out',
        payload: {
          payload: JSON.stringify({
            title: 'Init Flow',
            target: data.flowId,
            payload: data.payload,
          }),
        },
      },
    })

    navigate(`/chats/${result.conversation.id}`)
  }

  useEffect(() => {
    if (hooks.length && !loading) {
      const event = hooks.pop()
      handleEvent(event)
      setHooks(hooks)
    }
  }, [loading, conversations, hooks, location])

  useEffect(() => {
    if (initialized && !self.current.initialized) {
      self.current.initialized = true
      if (sdk.chatStartupType === 'chat_unique') {
        if (conversations.length) {
          const [first] = conversations
          navigate(`/chats/${first.id}`)
        } else {
          navigate(`/chats/newConversation`)
        }
      } else if (redirect) {
        redirect && navigate(redirect)
      } else if (sdk.chatStartupType === 'chat') {
        if (conversations.length) {
          navigate(`/chats`)
        } else {
          navigate(`/chats/newConversation`)
        }
      }

      window.parent.postMessage('init', '*')
    }
  }, [initialized, conversations])

  useEffect(() => {
    if (hooks.length && !loading) {
      const event = hooks.pop()
      handleEvent(event)
      setHooks(hooks)
    }
  }, [loading, conversations, hooks, location])

  return (
    <Stack className="h-100 w-100">
      <Suspense
        fallback={
          <LoadingContainer
            container
            alignItems="center"
            justifyContent="center"
          />
        }
      >
        <Routes>
          {routes.map((route: any) => (
            <Route
              {...route}
              key={route.path}
              element={<AppRoute {...route} />}
            />
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Stack>
  )
}

export default memo(Main)
