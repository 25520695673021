import { getMessageText } from "../../helper"
import * as Styled from "./style"

const HSM = (message) => {
  return (
    <Styled.Content key={message.id}>
      {!!message?.payload?.image && (
        <Styled.Image src={message?.payload?.image} />
      )}

      {!!message?.payload?.text && getMessageText(message.payload.text)}
    </Styled.Content>
  )
}

export default HSM
