import { useContext } from 'react'
import Grid from '@mui/material/Grid'
import { SessionContext } from '@Provider/index'
import * as Styled from './styled'
import { useParams } from 'react-router-dom'
import useSendMessage from '@hooks/useSendMessage'

export function QuickReplies({ quickReplies, ...props }) {
  const { id } = useParams()
  const { sdk } = useContext(SessionContext)
  const { sendButton } = useSendMessage(sdk.tenant, id)

  const handleQuickReply = quickReply => {
    sendButton({
      title: quickReply.label,
      payload: quickReply.payload,
    })
  }

  return (
    <Styled.Container>
      <Grid container justifyContent="flex-end" spacing={1} wrap="wrap">
        {quickReplies.map((reply, index) => (
          <Grid item key={index} style={{ flex: '0 0 auto' }}>
            <Styled.Reply
              rounded={sdk.quickReplies === 'rounded'}
              color={sdk.suggestionsColor || sdk.color}
              theme={sdk.theme}
              onClick={() =>
                props?.onClick ? props.onClick(reply) : handleQuickReply(reply)
              }
            >
              {reply.label}
            </Styled.Reply>
          </Grid>
        ))}
      </Grid>
    </Styled.Container>
  )
}

export default QuickReplies
