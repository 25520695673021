import { useEffect } from 'react'
import useWebSocketEvent from '@hooks/useWebSocketEvent'
import { useStoreActions } from 'easy-peasy'
import { ReadyState } from '@hooks/useWebSocket/constants'
import useInterval from '@Commons/hooks/useInterval'

export default function WebsocketProvider({ queryParams = {}, children }) {
  const { sync_conversations, message_status, new_message } = useStoreActions(
    ({ chat }: any) => ({
      sync_conversations: chat.sync_conversations,
      message_status: chat.message_status,
      new_message: chat.new_message,
    }),
  )

  const { close, readyState, sendMessageWithAck, joinRoom } = useWebSocketEvent(
    {
      message_status,
      new_message,
      queryParams,
    },
    {
      onReconnect: () => {
        sync_conversations()
      },
    },
  )

  useInterval(() => {
    const checkInactivity = async () => {
      if (readyState === ReadyState.OPEN) {
        try {
          await sendMessageWithAck('ping', { data: 'ping' })
        } catch (e) {
          close()
        }
      }
    }

    checkInactivity()
  }, 300000)

  useEffect(() => {
    sync_conversations()
  }, [])

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      joinRoom(
        localStorage.getItem('fingerprint') || (window as any).fingerprint,
      )
    }
  }, [readyState])

  return <>{children}</>
}
