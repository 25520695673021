import { useContext } from "react"
import { Avatar, Box, Stack, Typography } from "@mui/material"
import useHover from "@hooks/useHover"
import { formatDate } from "@Commons/utils/date"
import { SessionContext } from "@Provider/index"
import i18n from "@Commons/i18n"
import Fade from '@mui/material/Fade'

export default (props) => {
  const { sdk } = useContext(SessionContext)
  const [ref, isHover]: any = useHover<any>()

  return (
    <Stack className="w-100" sx={{ ...(props.last && { pb: 2 }), }}>
      <Stack
        ref={ref}
        direction="row"
        alignItems="flex-end"
        className="w-100"
        spacing={1}
        sx={{ py: '2px' }}
      >
        <Avatar src={sdk.avatar} sx={{ width: 40, height: 40, opacity: props.last ? 1 : 0 }} />

        <Fade in timeout={400}>
          <Box className="w-100" sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Box
              sx={{
                overflow: 'hidden',
                flexGrow: 1,
                position: 'relative',
              }}
            >
              <Box
                className={['generic', 'video'].includes(props.message.type) ? 'w-100' : ''}
                sx={{
                  bgcolor: sdk.receiverChat || "background.paper",
                  color: theme => sdk.receiverTextColor || (theme.palette.mode === 'dark' ? "#000" : "#FFF"),
                  borderRadius: 3,
                  borderBottomLeftRadius: 0,
                  p: 1,
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  display: 'inline-flex',
                  flexGrow: 1,
                }}
              >
                {props.children}
              </Box>
            </Box>

            <Typography
              fontWeight={300}
              color="text.secondary"
              fontSize={10}
              sx={{
                opacity: (props.last || isHover) ? 1 : 0,
                zIndex: 2,
              }}>
              {sdk.title || i18n.t('Bot')} · {formatDate(props.message.createdAt, "dd/MM/yy HH:mm")}
            </Typography>
          </Box>
        </Fade>
      </Stack>

      {/* {sdk.alwaysShowQuickReply && props.message.payload.quickReplies?.length ? (
        <Box sx={{ pl: '40px' }}>
          <QuickReplies
            sdk={sdk}
            quickReplies={props.message.payload.quickReplies}
            handleQuickReply={handleQuickReply}
          />
        </Box>
      ) : null} */}
    </Stack>
  )
}
