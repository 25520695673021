import reactHtmlParser from 'react-html-parser'
import { format } from '@flasd/whatsapp-formatting'
import { Grid } from '@mui/material'
import i18n from '@Commons/i18n'

export const LAST_MESSAGE_BY_TYPE = {
  text: (message) => {
    return (
      <span>
        {reactHtmlParser(format(message?.payload?.text || ''))}
      </span>
    )
  },
  hsm: (message) => {
    const text = message?.payload?.text || "Template HSM"
    return (
      <span>
        {reactHtmlParser(format(text || ''))}
      </span>
    )
  },
  file: (message) => {
    const fileTypes = {
      image: {
        icon: require("@Assets/img/photo.png"),
        label: "Foto",
      },
      video: {
        icon: require("@Assets/img/video.png"),
        label: "Vídeo",
      },
      audio: {
        icon: require("@Assets/img/headphones.png"),
        label: "Áudio",
      },
      file: {
        icon: require("@Assets/img/document.png"),
        label: "Documento",
      },
      story_mention: {
        icon: require("@Assets/img/document.png"),
        label: "Reação de story",
      },
    }

    const { icon, label } = fileTypes?.[message.payload.fileType] || {
      icon: require("@Assets/img/document.png"),
      label: "Mensagem"
    }

    return (
      <Grid container alignItems="center" className="ml-1">
        <Grid item className="flex">
          <img alt="" src={icon} style={{ height: 16, marginRight: 5 }} />{" "}
        </Grid>
        <Grid>{label}</Grid>
      </Grid>
    )
  },
  sticker: (message) => {
    return (
      <Grid container alignItems="center" className="ml-1">
        <Grid item className="flex">
          <img
            alt=""
            src={require("@Assets/img/sticker.png")}
            style={{ height: 16, marginRight: 5 }}
          />{" "}
        </Grid>
        <Grid>{i18n.t("Sticker")}</Grid>
      </Grid>
    )
  },
  postback: (message) => {
    return message?.payload?.title || message?.payload?.text
  },
  system: (message) => {
    if (message?.payload?.type === "INIT_CHAT") return `Atendimento iniciado`
    if (message?.payload?.type === "ACTIVE_CHAT")
      return `Atendimento ativo iniciado`

    if (message?.payload?.type === "CHAT_TRANSFERED") {
      if (message.payload.agent) {
        return `Atendimento transferido para ${message.payload.agent}`
      }
      return `Atendimento transferido enviado para a fila`
    }

    return message?.payload?.type
  },
  location: (message) => {
    return (
      <Grid container alignItems="center" className="ml-1">
        <Grid item className="flex">
          <img
            alt=""
            src={require("@Assets/img/location.png")}
            style={{ height: 16, marginRight: 5 }}
          />{" "}
        </Grid>
        <Grid>{i18n.t("Location")}</Grid>
      </Grid>
    )
  },
  contact: (message) => {
    return (
      <Grid container alignItems="center" className="ml-1">
        <Grid item className="flex">
          <img
            alt=""
            src={require("@Assets/img/contact.png")}
            style={{ height: 16, marginRight: 5 }}
          />{" "}
        </Grid>
        <Grid>{i18n.t("Contact")}</Grid>
      </Grid>
    )
  },
  generic: (message) => {
    const element = message.payload.elements[0]
    return `${element.title} ${element.subtitle}`
  },
  list: (message) => {
    return message.payload.text
  },
  button: (message) => {
    return message.payload.text
  },
}
