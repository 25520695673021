import { format } from "@flasd/whatsapp-formatting"
import Linkify from 'linkify-it'
import tlds from 'tlds'
import { encode } from 'html-entities'

export function escapeHtml(string) {
  // test if string contains html tags
  const regexHtml = /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/ig
  if (regexHtml.test(string)) {
    let str = String(string)
    const matches = str.match(regexHtml)

    matches?.forEach(match => {
      const index = str.indexOf(match)

      str = str.slice(0, index) +
        encode(str.slice(index, index + match.length)) +
        str.slice(index + match.length)
    })

    return str
  }
  return string
}

const replaceLinks = (text: string): string => {
  const linkify = new Linkify()
  linkify.tlds(tlds)

  const matches = linkify.match(text)

  if (!matches) return text

  const parts: string[] = [];
  let lastIndex = 0;
  matches.forEach((match, i) => {
    // Push preceding text if there is any
    if (match.index > lastIndex) {
      parts.push(text.substring(lastIndex, match.index));
    }

    parts.push(`<a href="${match.url}" target="_blank" ref="noopener noreferrer">${match.text}</a>`);

    lastIndex = match.lastIndex;
  });

  // Push remaining text if there is any
  if (text.length > lastIndex) {
    parts.push(text.substring(lastIndex));
  }

  return parts.join('');
}

export const handleTextPayload = (payload) => {
  try {
    if (typeof payload !== "string") {
      return JSON.stringify(payload)
    }
  } catch (e) { }

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: replaceLinks(format(escapeHtml(payload)))
      }}
    />
  )
}

export const getMessageText = payload => {
  if (!payload) {
    return ''
  }

  return typeof payload === "string"
    ? handleTextPayload(payload)
    : handleTextPayload(payload.text)
}
