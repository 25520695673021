import { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ChatContext } from '@Components/Chat/context'
import { IChatContext } from '@Commons/types/chat'
import Contact from '@Components/Contact'
import Header from '@Components/Header'

export default function ChatHeader({ sdk }) {
  const { id } = useParams()
  const { contact } = useContext<IChatContext>(ChatContext)
  const navigate = useNavigate()

  return (
    <Header
      goBack={
        sdk?.chatStartupType !== 'chat_unique'
          ? () => {
              sdk?.chatStartupType === 'chat'
                ? navigate('/onlyChats')
                : navigate('/chats')
            }
          : undefined
      }
      sdk={sdk}
    >
      <Contact
        name={contact?.name || ''}
        picture={contact?.picture}
        // subtitle={i18n.t('Specialist')}
      />
    </Header>
  )
}
