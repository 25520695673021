import styled, { css } from "styled-components"

export const Content = styled.div`
  padding: 5px 10px;
  border-radius: 1em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: white;
  z-index: 1;
  position: relative;
  white-space: pre-line;
  background: ${(props) => props.theme.palette.secondary.main};
`
