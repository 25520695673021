import { ApolloProvider } from "@apollo/client"
import { BrowserRouter as Router } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ThemeProvider from "@Components/ThemeProvider"
import Main from "@Containers/Main"
import Provider from "@Provider"
import client from "@resources/api/graphql"
import 'react-spring-bottom-sheet/dist/style.css'
import { Amplify } from "aws-amplify"
import awsconfig from './aws-exports'
import "./App.css"

Amplify.configure(awsconfig)

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Provider>
        <ThemeProvider>
          <>
            <Router>
              <Main />
              <ToastContainer />
            </Router>
          </>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  )
}

export default App
