import { useEffect, useRef } from 'react'

function useInterval(callback, delay) {
  const savedCallback = useRef(callback)
  const id = useRef<NodeJS.Timeout>()

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return
    }

    id.current = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id.current)
  }, [delay])

  return id.current
}

export default useInterval
