import { Divider, List, Paper, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { useState } from 'react'
import { getMessageText } from '../../helper'
import Buttons from '../utils/Buttons'
import { Button } from '../utils/Buttons/styled'

export default function ListMessage(props) {
  const [openList, setOpenList] = useState(false)

  return (
    <Box key={props?.id} sx={{ p: 1 }}>
      <Typography>{getMessageText(props)}</Typography>
      <Button onClick={() => setOpenList(!openList)} sx={{ mt: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <img src={require('@Assets/img/list.png')} width="15" />
          <Typography>{props?.button}</Typography>
        </Stack>
      </Button>

      <Collapse in={openList}>
        <Paper
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 4,
            overflow: 'hidden',
          }}
        >
          {props?.listOptionsTitle && (
            <>
              <Box sx={{ p: 1 }}>
                <Typography variant="body2" textAlign="center">
                  {props?.listOptionsTitle}
                </Typography>
              </Box>
              <Divider />
            </>
          )}

          {props?.sections?.length && (
            <div style={{ width: '100%' }}>
              <List
                dense
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  position: 'relative',
                  '& ul': { padding: 0 },
                  pt: 1,
                }}
                subheader={<li />}
              >
                {props.sections.map((section, i) => (
                  <>
                    {i > 0 && (
                      <Divider
                        component="li"
                        sx={{ listStyleType: 'none', my: 0.5 }}
                      />
                    )}
                    <Box
                      component="li"
                      key={`list-section-${section.id}`}
                      sx={{ p: 1 }}
                    >
                      <Typography align="center" color="text.secondary">
                        {section.title}
                      </Typography>
                      <Buttons buttons={section.buttons} />
                    </Box>
                  </>
                ))}
              </List>
            </div>
          )}
        </Paper>
      </Collapse>
    </Box>
  )
}
